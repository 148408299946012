@import "../../theme.scss";
@import "../../reponsive.scss";

.wrapperSignIn {
  margin: 45px 0;
}

// form
.signInFormContainer {
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  background-color: #eeeeee;
  border: 1px solid #eee;
}
.inputItem {
  & input {
    height: 50px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  & > span > span {
    width: 50px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
.rowCreateAccount {
  margin-top: 1rem;
  text-align: center;
  font-size: 16px;

  span {
    &:nth-child(1) {
      color: black;
      margin-right: 8px;
    }
    &:nth-child(2) {
      color: $warning-color;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.formItemOtp {
  text-align: center;
  & > div > div > div {
    margin-top: 1rem;
  }
}
.otpInput {
  width: 2.5rem !important;
  height: 2.5rem;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
