@import "../../reponsive.scss";
@import "../../global.scss";
.red-color {
  color: #bd0000 !important;
}
.broker-container {
  background-color: #f0f2f5;
  .bread-custom-container {
    padding-left: 0px;
    .section-wrap {
      padding-left: 0px;
    }
  }
  .header {
    background: #dedad3;
    section {
      position: relative;
      width: 100%;
      height: 365px;
      overflow: hidden;
      background: #dedad3;
      img {
        position: absolute;
        height: auto;
        top: 0;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        height: auto;
        left: 50%;
        transform: translateX(-50%) translateY(-35%);
      }
    }

    .icon-verify {
      color: #408FF7;
    }

    .ant-rate-star-first,
    .ant-rate-star-second {
      color: #bd0000 !important;
    }
    .banner {
      text-align: center;
      img {
        width: 100%;
        max-height: 450px;
      }
    }
    .information {
      padding: 0px 20px;
      position: relative;
      background: #dedad3;
      padding-bottom: 10px;
      .avatar {
        .image-cropper {
          position: relative;
          width: 200px;
          height: 200px;
          padding: 23px;
          img {
            background: white;
            width: 100%;
            border-radius: 100%;
            height: 100%;
          }
        }
      }
      .name-rate {
        margin-top: 10px;
        display: inline-grid;
        h3 {
          float: left;
          margin-right: 10px;
          margin-bottom: 0;
        }
        ul {
          float: left;
        }
        .name-role {
          color: #bf8100;
          margin-bottom: 0.5rem;
          font-size: 13px;
        }
      }

      .text-introduce {
        h4 {
          margin-top: 20px;
        }
        p {
          text-align: justify;
        }
      }
      .contact {
        .btn {
          float: left;
        }
        .ant-btn {
          border-radius: 100px;
          height: 100%;
        }
        .ant-btn-danger {
          background-color: #bf8100 !important;
          border-color: #bf8100 !important;
        }
        .ant-btn-primary {
          background-color: #bd0000 !important;
          margin-right: 10px;
        }
        .zalo-img {
          border-radius: 100px;
          margin-left: 10px;
          vertical-align: bottom;
        }
        .messenger-img {
          border-radius: 100px;
          margin-left: 10px;
          vertical-align: bottom;
        }
      }
      .area-sales {
        padding-top: 10px;
        padding-left: 25px;
        .ant-list.ant-list-split {
          border-radius: 5px;
        }
        .ant-list-split .ant-list-item {
          border: none !important;
        }
        .ant-list-item {
          font-size: 16px;
          padding: 6px 6px;
        }
      }
    }
  }

  .body {
    background: white;
    padding-left: 20px;
    .body-content {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      float: left;
      padding-right: 10px;
      .project-column {
        .section-wrap {
          padding-left: 0px;
        }
      }
    }
  }
}
.siteFooter {
    background-color: #2f2929;
    width: 100%;
    float: left;
    .img-logo {
      img {
        width: 200px;
        display: inline-block;
      }
    }
    .footerTextArea {
      width: 100%;
      float: left;
      color: white;
    }
}

@media (max-width: 901px) {
  .broker-container {
    .header {
      section {
        height: 200px;
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .broker-container {
    .header {
      .information {
        .area-sales {
          padding: 0px;
          margin-bottom: 10px;
        }
      }
    }
    .data-list {
      padding: 0px 10px;
    }
  }
  .broker-container .body {
    padding-left: 0px;
  }
  .article-body {
    img {
      width: 100%!important;
      height: 100%!important;
    }
  }
}
