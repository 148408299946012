.keywordHot{
  border-bottom: 1px solid #9999;
  padding-bottom: 20px;
  :global .ant-descriptions-title{
    font-weight: bold;
    font-size: 18px;
    color: #bf8100;
  }
  :global .ant-descriptions-item {
    padding-bottom: 10px;
    .ant-descriptions-item-content{
      color: white;
      text-transform: capitalize;
      a {
        color: unset;
        &:hover{
          color: #cc2621;
        }
      }
    }
  }
}
