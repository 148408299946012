@import "../../../reponsive.scss";
.boxShadow {
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
}

.myProfileInfo {
  @extend .boxShadow;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  padding: 25px;
}
.profileImage {
  max-width: 100%;
}
.profileContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 1rem;
}
// editUserInfoForm
.formWrapper {
  @extend .boxShadow;
  border-radius: 5px;
  margin-top: 25px;
  padding: 25px;
}
.formContainer {
  @extend .boxShadow;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.inputItem {
  height: 40px;
  border-radius: 8px;
}
.selectCategory {
  & > div {
    @extend .inputItem;
  }
}
.uploadImage {
  div {
    width: 100%;
  }
  width: 100%;
  button {
    border-radius: 8px;
    height: 50px;
  }
}
.bannerImageWrapper {
  width: 100%;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  margin-bottom: 1rem;
}
.uploadBanner {
  width: 100%;
  height: 120px;
  & > div {
    height: 100% !important;
    width: 100% !important;
    & > span {
      height: 120px !important;
      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
// change password form
.formTop {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.formBottom {
  margin-top: auto;
  flex-shrink: 0;
}

//
.avatar {
  span {
    width: 153px;
    height: 153px;
    line-height: 153px;
    font-size: 18px;
    @media screen and (max-width: $screen-md) {
      width: 120px;
      height: 120px;
      line-height: 120px;
    }
  }
}
