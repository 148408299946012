@import "../../reponsive";

.menuBottom {
  display: none;
  background: white;
  @media only screen and (max-width: $screen-sm-max) {
    display: flex;
    margin: 0;
  }
  .menuItem {
    flex: 1 1 auto;
    text-align: center;
    position: relative;
    list-style-type: none;
    width: calc(100% / 5);
    a {
      display: block;
      padding: 5px;
      position: relative;
      z-index: 1;
      :global .anticon {
        font-size: 20px;
        vertical-align: middle;
      }
      span {
        display: block;
        font-size: 11px;
        width: 100%;
      }
    }
    .circle {
      border-radius: 50%;
      background: #ffba00;
      position: absolute;
      left: calc(50% - 35px);
      top: -10px;
      width: 70px;
      height: 70px;
      z-index: 0;
    }
    .wallperCircle {
      position: absolute;
      top: -21px;
      left: calc(50% - 35px);
    }
  }
}

.menuMore {
  :global .ant-drawer-content-wrapper {
    height: 100% !important;
  }
}
