@import '../../reponsive';
.heading {
  color: white;
}
.inputSearch {
  :global {
    .ant-input-suffix {
      padding: 13px;
      right: 0;
    }
  }
}
.rowInput {
  margin-bottom: unset !important;
}
.filter-title {
  text-decoration: transform;
  font-size: 19px;
}
.btnGoogle {
  background-color: #4286f5;
  color: #fff;
}

.btnFacebook {
  background-color: #3b5999;
  color: #fff;
}

.iconGoogleSignIn::before {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: -10px;
  margin-bottom: -2px;
  content: '';
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-image: url(//static.trulia-cdn.com/images/icons/icon_google_signin.svg);
}

.iconFacebookSignIn::before {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: -10px;
  margin-bottom: -2px;
  content: '';
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-image: url('../../images/facebook.svg');
}

.entryHeader {
  min-height: 270px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  @media only screen and (max-width: $screen-sm-max) {
    min-height: 270px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(8, 29, 80, 0.2) 1.24%,
      rgba(8, 29, 80, 0.9) 106.38%
    );
    opacity: 0.6;
  }
  .entryFeatured {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  }
  .headerContent {
    z-index: 35;
    @media screen and (min-width: 768px) {
      min-width: 650px;
    }
    @media only screen and (max-width: $screen-sm-max) {
      padding: 0 15px;
    }
    .pageTitle {
      font-size: 28px;
      line-height: 40px;
      color: white;
      margin: 0;
      text-transform: uppercase;
      font-weight: bold;
      @media only screen and (max-width: $screen-sm-max) {
        font-size: 24px;
      }
      .tabNational {
        background: #bf0000;
        color: white;
        margin-left: 5px;
        padding: 5px 12px;
        border-radius: 5px;
      }
    }
    .entrySubtitle {
      color: white;
      margin-bottom: 33px;
      font-weight: 300;
      font-size: 16px;
      margin-top: 10px;
      @media only screen and (max-width: $screen-sm-max) {
        margin-bottom: 13px;
      }
      > p {
        margin: 0;
      }
    }
    .searchForm {
      display: flex;
      flex-direction: column;
      .radioSelected {
        margin-bottom: 10px;
      }
    }
    .autoComplete {
      :global {
        .ant-select-selection__placeholder {
          margin-left: 100px;
        }
        .ant-input-group-addon {
          .ant-select-arrow {
            display: block;
          }
        }
        .ant-select-selection-selected-value {
          padding-left: 1rem;
        }
      }
    }
  }
  .topCategories {
    width: 100%;
    position: absolute;
    bottom: 32px;
    padding: 0 170px;
    left: 0;
    text-align: center;
    z-index: 30;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    @media only screen and (max-width: $screen-md-max) {
      padding: 0 32px;
    }
    @media only screen and (max-width: $screen-sm-max) {
      padding: 0;
    }
    .whatBuy {
      @media only screen and (max-width: $screen-md-max) {
        display: none;
      }
    }
    a {
      max-width: 80px;
      -webkit-box-flex: 0;
      -ms-flex: 0 1 80px;
      flex: 0 1 80px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin-right: 1px;
      padding: 15px 6px 14px;
      border-radius: 2px;
      color: white;
      font-size: 0.875rem;
      background: rgba(255, 255, 255, 0.15);
      @media only screen and (max-width: $screen-sm-max) {
        &:nth-of-type(n + 6) {
          display: none;
        }
        background: none;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      .catIcon {
        display: block;
        text-align: center;
      }
      .catText {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        font-size: 13px;
        font-weight: 300;
        text-align: center;
        display: block;
      }
    }
    .ctaText {
      position: absolute;
      left: 100%;
      bottom: 100%;
      margin-left: 20px;
      width: 150px;
      color: white;
      -webkit-hyphens: initial;
      -ms-hyphens: initial;
      hyphens: initial;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 10px;
        margin-top: 5px;
        width: 58px;
        height: 50px;
        display: block;
        background: url(https://cdn.demos.pixelgrade.com/wp-content/themes/listable/assets/svg/arrow-curved.svg)
          center center no-repeat;
        background-size: cover;
      }
    }
  }
}
