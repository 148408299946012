.district-list,
.type-list {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;

  &__item {
    margin-top: 10px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
.type-container {
  h2 {
    margin-top: 1rem;
  }
}
.price-by-month {
  &__map {
    padding-left: 0;

    @media screen and (min-width: 600px) {
      padding-left: 1rem;
    }

    .map-estimate {
      margin-top: 0;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: white;
      height: 300px;

      @media screen and (min-width: 600px) {
        height: 580px;
      }
    }
  }
}
