@import './src/global';
@import '../../reponsive.scss';

.siteContent {
  position: relative;
}
.ant-drawer-body {
  padding: 0;
}
.widget-project {
  .name-project {
    color: #bf8100;
    font-size: 20px;
  }
}
.vip-1 {
  border-top: 1px solid #ffcc00 !important;
  .title-item {
    padding-top: 15px !important;
  }
  .label {
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 50px solid #ffcc00;
    border-left: 60px solid transparent;
    position: relative;
    color: white;
    margin-top: -13px;
    position: absolute;
    right: 0;
    .text {
      position: absolute;
      top: -45px;
      font-size: 14px;
      right: 5px;
      text-align: center;
      font-family: arial;
      transform: rotate(45deg);
      display: block;
    }
  }
}
.vip-2 {
  background-color: #fbf3ae;
  .label {
    z-index: 1;
    position: absolute;
    right: 17px;
    bottom: 50%;
    .text {
      font-size: 15px;
      padding: 5px 10px;
      background-color: #52c41a;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: $screen-md) {
    .label {
      bottom: 20%;
    }
  }

  .small {
    .label {
      bottom: 33%;
    }
  }
}
.vip-3 {
  background-color: #fbf3ae;
  min-height: 200px;
  @media screen and (max-width: 1024px) {
    min-height: 250px;
  }
  @media screen and (max-width: $screen-md) {
    min-height: 150px;
  }
  .ant-card-body {
    min-height: 210px;
  }
  .small {
    .imageResize {
      padding: 60px 0px !important;
      float: left;
    }
    .contact {
      margin-left: -50px;
      position: absolute;
      bottom: 0px;
    }
    @media screen and (max-width: $screen-md) {
      .contact {
        margin-left: -15px;
      }
    }
    @media screen and (max-width: $screen-sm) {
      .contact {
        left: 10px;
        position: absolute;
      }
    }
    .label {
      bottom: 50%;
    }
    @media screen and (max-width: $screen-sm) {
      .label {
        bottom: 55%;
      }
    }
  }
  .label {
    position: absolute;
    right: 10px;
    bottom: 50%;
    .text {
      font-size: 15px;
      padding: 5px 7px;
      background-color: red;
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }
  }
  .contact {
    padding: 10px;
    display: flex;
    button {
      float: right;
    }
    .ant-btn-primary {
      margin-right: 10px;
      background-color: #bd0000 !important;
      border-radius: 100px;
      height: 100%;
    }
    .ant-btn-danger {
      background-color: #bf8100;
      border-radius: 100px;
      height: 100%;
      border-color: transparent;
    }
    .zalo-img {
      border-radius: 100px;
      margin-left: 10px;
      height: 100%;
      margin-right: 10px;
    }
  }
  @media screen and (max-width: $screen-sm) {
    .contact {
      margin-left: -15px;
    }
  }
}

.siteContent {
  .ant-typography {
    margin: 10px 0;
  }
  .span-ivestor {
    margin-bottom: 10px;
  }

  .investor-content {
    .item-investor {
      margin-top: 20px;
      float: left;
      .img {
        margin: 0 auto;
        display: block;
        position: relative;
        height: 160px;
        width: 160px;
        padding: 20px;
        background: white;
        cursor: pointer;
        transition: all 0.2s ease;
        border: 1px solid rgba(131, 131, 131, 0.1);
        &:hover {
          border: 1px solid #838383;
        }
        img {
          width: 100%;
          height: auto;
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 20px;
        }
      }
    }
    .slick-arrow {
      @include slick-arrow;
    }
  }
  .transaction {
    .item {
      padding: 10px;
      .avata {
        img {
          width: 100%;
        }
        margin-top: 5px;
      }
    }
    .increase {
      color: #5b8c00;
    }
    .descrease {
      color: #a8071a;
    }
  }
}
.sidebar-banner {
  margin-bottom: 24px;
}
.heading-container {
  margin: 20px 0;
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 54px;
    margin-bottom: 0;
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
}
