@import '../../reponsive.scss';
.data-list {
  //a:hover { color: inherit!important;}
  .data-item {
    margin-bottom: 20px;
    .image  {
      img {
        width: 100%;
        height: 100px;
      }
    }
    .image.real-estates {
      img {   width: 100%; }
      position: relative;
      .card-tag-left {
        position: absolute;
        left: 5px;
        margin-top: 5px;
      }
    }
    .description {
      padding-left: 15px;
      .title-item {
        font-size: 16px;
        color: black;
        font-weight: bold;
      }
      .date {
        font-size: 14px;
        color:grey;
        display: inline-block;
        width: 100%;
        .user_name {
          font-weight: bold;
          color: #cc9036;
        }
        .icon {
          margin-right: 5px;
        }
      }
    }
  }
  .data-item.real-estates {
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    .title-item {
      font-weight: normal;
    }
    .location {
      font-size: 13px;
      padding: 3px 0px;
      .icon {
        margin-right: 5px;
      }
    }
    .price-acreage {
      width: 100%;
      padding: 5px 0px;
      font-size: 13px;
      .price, .acreage{
        font-weight: bold;
        font-size: 20px;
      }
      .price {
        color: #BF0000;
      }
      .acreage {
        color: #cc9036;
      }
    }
  }
  .data-item.real-estates:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
}

.pagination-container {
  text-align: center;
}

@media screen and (max-width: $screen-md) {
  .pagination-container { text-align: center;}
  .data-list-container { padding: 0px!important;}
}
@media screen and (max-width: $screen-sm) {
  .data-list {
      .data-item {
        .image  {
          img {
            width: 100%;
            height: 100%;
          }
        }
        .description {
          .title-item{
            font-size: 14px;
          }
          .price-acreage{
            .price, .acreage{
              font-size: 18px;
            }
          }
          .location{
            font-size: 11px;
          }
          .date{
            font-size: 12px;
          }
        }
      }
  }
}
