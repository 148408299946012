@import "styles/_mixins.scss";

.projectItem {
  width: 100%;
  .description {
    @include textClamp(2)
  }
  .acreage {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #374151;
    margin: 1rem 0;
  }
  .itemLeft {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    & > img {
      height: 164px;
    }
  }
  .itemRight {
    padding-left: 1rem;
    .projectCreatedAt {
      margin: 0 4px;
    }
    .keyword {
      margin-right: 4px;
    }
  }
  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #060708;
  }
  @include maxWidth(767px) {
    &:not(.widgetSmall) {
      .itemRight {
        padding-left: 0;
        margin-top: 1rem;
      }
      .itemLeft {
        border: none;
      }
    }
  }
}
.widgetSmall {
  .itemLeft {
    & > img {
      height: 76px;
    }
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #060708;
  }
}
