@import "../../../reponsive.scss";

.userPopoverWrapper {
  position: relative;
  @media only screen and (max-width: $screen-md-max) {
    display: none;
  }
}
.userButton {
  width: 88px;
  height: 48px;
  border-radius: 100px;
  & > i {
    font-size: 20px !important;
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
}
.userMenuItems {
  position: absolute;
  right: 0;
  top: 58px;
  width: 200px;
  background-color: white;
  filter: drop-shadow(0px 1px 5px rgba(31, 41, 55, 0.1))
    drop-shadow(0px 3.6px 13px rgba(31, 41, 55, 0.07))
    drop-shadow(0px 8.4px 23px rgba(31, 41, 55, 0.06))
    drop-shadow(0px 23px 35px rgba(31, 41, 55, 0.05));
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.userMenuItem {
  line-height: 44px;
  color: #374151 !important;
  list-style: none;
  img {
    height: 1rem;
    width: 1rem;
    filter: brightness(0%);
    margin-right: 8px;
  }
  & > a {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 16px;
    &:hover {
      background-color: #fdf6ea;
      color: #e2a329 !important;
      & > img {
        filter: brightness(100%);
      }
    }
    @media only screen and (max-width: $screen-md-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.userInfo {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  @media only screen and (max-width: $screen-md-max) {
    padding: 0;
  }
}
.fullNameWrapper {
  width: 80%;
  display: flex;
  align-items: flex-end;
}
.fullName {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
}
.userInfoMobile {
  display: none;
  @media only screen and (max-width: $screen-md-max) {
    display: block;
  }
}
