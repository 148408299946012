@import "../../reponsive.scss";
@import "styles/_mixins.scss";

.projectCartsWrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
.projectCartList {
  @include flexbox(stretch, flex-start, row, wrap);
  margin-left: -20px;
}
.projectHeading {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #bf0000;
}
.projectCartItem {
  width: calc(25% - 20px);
  height: 196px;
  margin-left: 20px;
  margin-bottom: 24px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.25s ease-out;
  position: relative;
  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  &:after {
    content: "";
    position: absolute;
    background-color: #000;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0.2;
  }
  @media screen and (max-width: $screen-lg-max) {
    height: 165px;
    width: calc(33.33333333% - 20px);
  }
  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
  }
  img {
    height: 100%;
  }
}
.projectTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.projectCartContent {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #fff;
  z-index: 10;
}
.projectBanner {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  padding: 96px 15px;
  text-align: center;
  position: relative;
  height: auto;
  max-height: 306px;
  background: url(../../images/blogcover.jpg);
  h1 {
    position: relative;
    z-index: 99;
    font-size: 40px;
    color: #fff;
    margin: 0;
    font-weight: 500;
    padding: 0 0 20px;
    line-height: 45px;
    letter-spacing: 1.7px;
    font-weight: 700;
    font-size: 44px;
    line-height: 66px;
    color: #ffffff;
  }
  p {
    position: relative;
    z-index: 99;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    margin: 0;
    line-height: 26px;
    font-weight: 100;
    background-color: #21b21e;
    max-width: 453px;
    display: inline-block;
    padding: 4px 12px;
    border-radius: 8px;
  }
}
.receiveStatusSubHeading {
  font-size: 20px;
  line-height: 30px;
  color: #878d97;
}