@import "./src/theme";
@import "../../reponsive.scss";
@import "styles/_mixins.scss";

.site-blog {
  .list-category {
    margin-bottom: 20px;
    border: 1px solid #ecedee;
    box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
      0px 4px 6px rgba(31, 41, 55, 0.08);
    border-radius: 8px;
    overflow: hidden;
    .ant-list-header {
      padding: 0;
    }
    .ant-list-items {
      padding: 0 12px;
    }
  }
  .catalog-style > a {
    color: rgba(0, 0, 0, 0.65);
  }
  .blog-banner {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    padding: 50px 15px;
    text-align: center;
    position: relative;
    height: auto;
    max-height: 220px;
    background: url(../../images/blogcover.jpg);
    h1 {
      position: relative;
      z-index: 99;
      font-size: 40px;
      color: #fff;
      margin: 0;
      font-weight: 500;
      padding: 0 0 20px;
      font-family: Tiempos, Arial, Helvetica, sans-serif;
      line-height: 45px;
      letter-spacing: 1.7px;
    }
    p {
      position: relative;
      z-index: 99;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      margin: 0;
      line-height: 26px;
      font-weight: 100;
      font-family: harmonia, Arial, Helvetica, sans-serif;
    }
  }
  .blog-content {
    margin-top: 60px;
    display: block;
    position: relative;
    float: none;
    .left-title {
      word-wrap: break-word;
      h2 {
        font-size: 24px;
        font-weight: 500;
        color: #30333a;
        margin: 0;
        font-family: Tiempos, Arial, Helvetica, sans-serif;
      }
    }
    .content {
      .blog-item-image {
        position: relative;
        width: 35%;
        margin-right: 4%;
        display: block;
        float: left;
        @media screen and (max-width: $screen-sm) {
          width: 100%;
        }
      }
      .ant-list-item-meta {
        width: 60%;
        float: left;
        @media screen and (max-width: $screen-sm) {
          width: 100%;
        }
      }
      .child-blog {
        h4 {
          width: 100%;
          display: block;
          margin: 0;
          height: auto;
          max-height: 64px;
          letter-spacing: 0.3px;
          font-size: 24px;
          line-height: 32px;
          font-weight: 500;
          font-family: Tiempos, Arial, Helvetica, sans-serif;
          overflow: hidden;
        }
        .categories {
          display: block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          .custom-date,
          .author-link {
            color: #838383 !important;
            font-size: 14px;
            font-family: harmonia, Arial, Helvetica, sans-serif;
          }
        }
        .blog-body {
          font-size: 16px;
          line-height: 24px;
          display: block;
          overflow: hidden;
          font-family: harmonia, Arial, Helvetica, sans-serif;
        }
      }
      .ant-pagination {
        margin-right: 20px;
      }
    }
    .right-aside {
      .ant-list {
        @media screen and (max-width: $screen-sm) {
          margin: 20px 0;
        }
      }
      .hot-blog-title {
        font-weight: 500;
        font-size: 1.17em;
      }
      .ant-list-header {
        h2 {
          color: $primary-color;
        }
      }
      .catalog-style {
        > a {
          color: $text-color;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    .post-heading {
      display: flex;
      overflow: hidden;
      color: #30333a;
      width: 100%;
      justify-content: space-between;
      .date-post {
        font-size: 14px;
        color: #838383;
        margin: 0;
      }
      .ant-page-header {
        padding: 0;
      }
    }
    .hs-featured-image-wrapper {
      width: 100%;
      margin: 0 auto 20px;
      display: flex;
      justify-content: center;
    }
    .post-body {
      word-wrap: break-word;
    }
    @include maxWidth(767px) {
      margin-top: 10px;
    }
  }
  .blog-detail-width {
    .blog-banner {
      max-width: 750px;
      width: 100%;
      margin: 0 auto;
      background: none;
      > h1 {
        margin: 0;
        padding-bottom: 20px;
        letter-spacing: 0.5px;
        font-size: 40px;
        line-height: 52px;
        font-weight: 500;
        color: #30333a;
      }
      .type-post {
        margin: 0;
        font-size: 14px;
        color: #838383;
        text-transform: uppercase;
      }
    }
  }
  
}
