.event-content {
  margin-top: 20;
  margin-bottom: 20;
  .event-html {
    background-color: white;
    padding: 0;
  }
  .description {
    margin-bottom: 20px;
  }
  .body {
    margin-bottom: 20px;
  }
  .post-heading {
    display: flex;
    overflow: hidden;
    color: #30333a;
    width: 100%;
    justify-content: space-between;
    .date-post {
      font-size: 14px;
      color: #838383;
      margin: 0;
    }
    .ant-page-header {
      padding: 0;
    }
  }
  .hs-featured-image-wrapper {
    width: 100%;
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
  }
  .post-body {
    word-wrap: break-word;
  }
}
.event-detail-width {
  .event-banner {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    background: none;
    > h1 {
      margin: 0;
      padding-bottom: 20px;
      letter-spacing: 0.5px;
      font-size: 40px;
      line-height: 52px;
      font-weight: 500;
      color: #30333a;
    }
    .type-post {
      margin: 0;
      font-size: 14px;
      color: #838383;
      text-transform: uppercase;
    }
  }
}
.site-event.detail {
  .child-event {
    .ant-list-header {
      padding-left: 10px;
      h2 {color: #bf0000;}
    }
    h4 {
      font-size: 17px;
    }
  }
}
