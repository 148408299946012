.cardList{
  .ant-skeleton-header{
    padding: 0;
  }
  .cardBlock{
    width: auto !important;
    .user_name {
      z-index: 1000;
      color: #BF0000;
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      transition: color 0.3s;
      display: inline-block;
    }
    .ant-card-meta-title {
      .cartTitle {
        color: #bd0000;
      }
      span {
        color: #BF8100;
      }
    }
  }
  .cartTitle{
    font-size: 20px;
  }
  .cartDes{
    font-size: 14px;
    p {
      max-height: 40px;
      height: 40px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
  }
  .cartImage {
    position: relative;
    background: rgba(0, 0, 0, 0.075) no-repeat center;
    background-size: cover;
    &:before{
      content: "";
      display: block;
      padding-top: 75%;
    }
    .card-tag-left{
      position: absolute;
      left: 10px;
      top: 10px;
      margin: 0;
    }
    .card-tag-right{
      position: absolute;
      right: 10px;
      top: 10px;
      margin: 0;
    }
  }
  .cartPrice{
    position: absolute;
    bottom: 15px;
    left: 0;
    padding: 5px 7px;
    font-size: 15px;
    background-color: rgba(80, 80, 80, 0.72);
    color: white;
  }
  &.slick-slider {
    .slick-dots li {
      &.slick-active{
        button{
          background: #ff4d4f;
        }
      }
      button{
        background: #cccccc;
        height: 5px;
      }
    }
    .slick-prev{
      left: -12px;
      &:before{
        content: '';
      }
    }
    .slick-next {
      right: -12px;
      &:before{
        content: '';
      }
    }
    .slick-prev, .slick-next{
      font-size: 0;
      line-height: 0;
      background: #BF8100;
      color: transparent;
      padding: 0;
      height: 36px;
      width: 36px;
      border-radius: 36px;
      top: calc((50% - 56px) / 2);
      box-shadow: 0 0 7px -4px #000;
      border: none;
      z-index: 1;
      transition: all .3s ease-in-out;
      text-align: center;
      > i {
        font-size: 16px;
        line-height: 40px;
        transition: all .3s;
        color: #fff;
      }
      &:hover{
        box-shadow: 0 0 5px 0 rgba(189, 126, 0, 0.5);
        > i {
          color: #ffffff;
        }
      }
    }
    .slick-disabled {
      color: transparent;
      background-color: rgba(189, 126, 0, 0.5);
    }
  }
  .ant-card-actions {  display: none;}
}
