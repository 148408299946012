.map-estimate {
  &__item {
    width: max-content;
    background: #bf0000;
    border-radius: 5px;
    display: flex;
    padding: 0 5px 0;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #bf0000;
    position: relative;
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    &:after {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 6px solid #bf0000;
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -10px;
      content: "";
    }

    span {
      margin: auto;
    }
  }
}
