.priceEstimate {
  .estimateList {
    background-color: #fdf6ea;
    padding: 16px;
    margin-top: 10px;
    .estimateBox {
      display: flex;
      align-items: flex-start;
      margin-bottom: 24px;
      .textTit {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #e2a329;
        margin-top: 8px;
      }
      .textTitRed {
        color: #bf0000;
      }
    }
  }
  .estimateSecondList {
    background-color: #fff2f2;
  }
}
.colRight {
  margin-left: 10px;
}
.estimateTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #bf0000;
}
:global {
  .avg-icon {
    float: left;
    width: 35px;
    height: 56px;
    background-image: url(/images/detail-house-icon.png);
    position: absolute;
    top: 0;
    left: 0;
  }
  .avg-2 {
    background-position: 36px 0;
  }
  .avg-3 {
    background-position: 84px 0;
  }
  .avg-1 {
    background-position: 133px 0;
  }
  .avg-5 {
    background-image: url(/images/increase-48.png);
    background-position: center;
    background-repeat: no-repeat;
  }
}
