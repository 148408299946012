.cardList.slick-slider.card-location{
  .slick-prev, .slick-next{
    top: calc((100% - 56px) / 2);
  }
  .slick-list {
    .slick-track{
      .slick-slide {
        > div {
          margin: 0 5px;
        }
      }
    }
  }
  .ant-list-split .ant-list-item {
    border-bottom: 0;
  }
  .ant-skeleton-header{
    padding: 0;
  }
}