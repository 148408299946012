@mixin slick-arrow($top: 50%, $right: -12px, $left: -12px) {
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 0;
  height: 36px;
  width: 36px;
  border-radius: 36px;
  top: $top;
  box-shadow: 0 0 7px -4px #000;
  background: #bf0000;
  border: none;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  text-align: center;
  &.slick-disabled {
    color: transparent;
    background-color: rgba(226, 62, 62, 0.5);
  }
  i {
    font-size: 16px;
    line-height: 40px;
    transition: all 0.3s;
    color: #fff;
  }
  &.slick-next {
    right: $right;
  }
  &.slick-prev {
    left: $left;
  }
}

.ant-form-item-label {
  > label {
    color: black;
  }
}
