@import "../../reponsive.scss";

.title {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.content {
  color: #060708;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}
.totalPrice {
  @extend .totalPriceLabel;
  font-weight: 700;
  margin-bottom: 0;
  & > span {
    padding-right: 5px;
  }
  text-align: end;
}
.totalPriceValue {
  color: #bf0000;
  font-size: 20px;
  font-weight: 700;
}
.unit {
  font-size: 14px;
  color: #979797;
  font-weight: 700;
}
.unitVAT {
  @extend .title;
  color: #000;
}
.totalPriceRow {
  display: flex;
  justify-content: space-between;
}
.totalPriceLabel {
  font-size: 1rem;
  font-weight: 600;
  color: #060708;
}
.textVAT {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5f6774;
}
.cartPayCard {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  @media only screen and (max-width: $screen-md-max) {
    margin-top: 2rem;
  }
}
.cartPayTemp {
  font-size: 16px;
  font-weight: 700;
  & > div {
    &:nth-child(2) {
      color: black;
    }
  }
}
.cartPayTotal {
  @extend .cartPayTemp;
  & > div {
    &:nth-child(2) {
      color: #bf8100;
      font-size: 1.25rem;
    }
  }
}

.uploadIdentityImage {
  & > div {
    width: 100% !important;
  }
}
.identityImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.attributeSuffix {
  margin-left: 4px;
}
.discountPriceRow {
  margin-top: 1rem;
}
