@import 'styles/_mixins.scss';

.desc {
  &-slider {
    @include flexbox(center);
    &-btn-container {
      width: 10%;
    }
    &-btn {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      @include flexbox(center, center);
    }
    &-btn-container.right {
      @include flexbox(stretch, right);
    }
    .ant-carousel {
      width: 80%;
      .slick-track {
        align-items: center;
      }
    }
    &-item {
      color: #878d97;
      @include textClamp(1);
      padding-left: 2rem;
      font-size: 1rem;
    }
    &-item.is-active {
      color: #bf0000;
    }
  }
  &-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #060708;
  }
  &-body {
    img {
      width: 100% !important;
      height: auto !important;
      object-fit: cover;
    }
  }
}
.project-detail {
  margin-bottom: 24px;
  &-banner {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    justify-content: center;
    @media only screen and (min-width: 1024px) {
      justify-content: flex-end;
    }
    @media only screen and (min-width: 768px) {
      align-items: center;
      height: 692px;
    }
  }
  &-desc-item {
    margin-top: 16px;
    ul {
      li {
        list-style: none;
        position: relative;
        padding-left: 10px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          background-color: rgba(0, 0, 0, 0.65);
          height: 4px;
          width: 4px;
          border-radius: 50%;
          top: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  &-download-btn {
    padding: 0;
    background-color: transparent;
    color: #bf0000;
    outline: none;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: transparent;
      color: #bf0000;
    }
    margin-bottom: 1rem;
  }
  &-info {
    background-color: #ecedee;
    border: 1px solid #ecedee;
    box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
      0px 4px 6px rgba(31, 41, 55, 0.08);
    padding: 1rem;
    @media only screen and (min-width: 1024px) {
      margin-right: 83px;
    }
    @media only screen and (min-width: 768px) {
      width: 489px;
      position: absolute;
      border-radius: 8px;
    }
    &-heading {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #bf0000;
      margin-bottom: 0;
    }
    &-location {
      color: #878d97;
      font-size: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #e0e0e0;
    }
    &-desc {
      display: flex;
      font-size: 16px;
      &-label {
        width: 40%;
      }
      &-value {
        width: 60%;
        color: #060708;
        font-weight: 500;
      }
      &-label {
        color: #5f6774;
      }
      &-value.investor-name {
        color: #bf0000;
        font-weight: 600;
      }
    }
    &-desc.product {
      display: block;
      &-value {
        margin-left: 1rem;
      }
    }
    &-desc {
      &-value.product {
        margin-left: 1.5rem;
        width: 100%;
      }
    }
    &-shopping-cart {
      span {
        margin-left: 4px;
      }
      height: 40px;
      background-color: #21b21e;
      color: white;
      font-size: 1rem;
      border-radius: 8px;
      &:hover,
      &:focus {
        background-color: #21b21e;
        border-color: #21b21e;
        color: white;
      }
    }
  }
}
.google-street-view {
  cursor: pointer;
  margin-bottom: 0;
  img {
    width: 48px;
    height: 48px;
  }
  .ant-list-item-meta-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #bf0000;
  }
}
.google-street-popup {
  .ant-modal-body {
    padding: 0px 1px;
  }
  .ant-btn {
    display: none;
  }
  .street-view-custom {
    height: 450px;
    .gm-iv-address-description {
      height: 100%;
    }
    .gm-iv-profile-url {
      float: left;
    }
  }
}
