@import "../../reponsive";

.siteHeader {
  align-items: center;
  flex-wrap: wrap;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  padding: 0;
  font-family: Nunito Sans, verdana, sans-serif;
  height: 70px;
  @media only screen and (max-width: $screen-sm-max) {
    height: 60px;
  }
  .ant-layout-header {
    height: 80px;
  }
  .blockHead {
    display: flex;
    /*@media only screen and (max-width: $screen-md-max) {
      display: flex;
    }*/
  }
  .searchBar {
    transition: transform 0.45s cubic-bezier(0.485, 1.65, 0.545, 0.835);
    @media only screen and (max-width: $screen-sm-max) {
      display: none;
      opacity: 0;
    }
    &.show {
      will-change: transform;
      @media only screen and (max-width: $screen-sm-max) {
        display: block;
        opacity: 1;
      }
    }
  }
  .siteBranding {
    float: left;
    font-weight: bold;
    font-size: 28px;
    flex: 1 1 auto;
    display: flex;
    @media only screen and (max-width: $screen-md-max) {
      position: relative;
      text-align: center;
      margin: 0;
      -ms-flex-preferred-size: 33%;
      flex-basis: 33%;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      padding: 0;
      float: none;
      display: flex;
      > a {
        flex-grow: 1;
      }
    }
    @media only screen and (max-width: $screen-sm-max) {
      font-size: 24px;
      > a {
        text-align: left;
      }
    }
    > a {
      color: black;
      float: left;
    }
    .mobileMenu {
      display: none;
      button {
        color: black;
        font-size: 24px;
      }
      @media only screen and (max-width: $screen-md-max) {
        display: block;
        position: absolute;
        right: 0;
      }
    }
    .searchMobile {
      display: none;
      button {
        color: black;
        font-size: 24px;
      }
      @media only screen and (max-width: $screen-sm-max) {
        display: block;
      }
    }
  }
  &.headerTransparent {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    > * {
      background-color: transparent !important;
    }
    .siteBranding {
      > a {
        color: white;
      }
    }
    .mobileMenu {
      button {
        color: white;
      }
    }
  }
  .siteMenu {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    @media only screen and (max-width: $screen-md-max) {
      display: none;
    }
  }
  .menuStyle {
    flex: 1 1 auto;
    justify-content: flex-end;
    display: flex;
    border-bottom: 0;
    line-height: 63px;
    font-family: verdana, sans-serif;
    @media only screen and (max-width: $screen-md-max) {
      display: none;
    }
    :global .ant-menu-item,
    :global .ant-menu-submenu {
      color: #bf0000;
      a {
        color: inherit;
        font-weight: 500;
      }
      &:hover,
      :global .ant-menu-submenu-title {
        border-color: white;
      }
      .ant-menu-submenu-title {
        .submenu-title-wrapper {
          color: black;
          text-transform: none;
        }
      }
    }
    &.menuTransparent {
      background-color: transparent;
      @media only screen and (min-width: $screen-md-max) {
        li {
          color: white;
          a {
            color: white;
          }
        }
      }
    }
  }
  .btnActionGroup {
    margin-left: 69px;
    @media only screen and (max-width: $screen-md-max) {
      display: none;
    }
  }
}
.drawerVisible {
  :global {
    .ant-menu {
      order: 1;
    }
  }
}
.postRealEstateBtn {
  color: #e2a329;
  border-color: currentColor;
  height: 44px;
  padding: 0 16px;
  border-radius: 25px;
}
.shoppingCartBtn {
  @extend .postRealEstateBtn;
  color: white;
  margin: 0 16px;
}
.btnActionGroup {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: $screen-md-max) {
  .btnActionGroup {
    flex-direction: column;
    align-items: unset;
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .postRealEstateBtn {
    order: 3;
    margin-top: 1rem;
  }
  .shoppingCartBtn {
    order: 2;
    margin: 0;
  }
}
