@import "../../reponsive.scss";
@import "./src/theme";
@import "styles/_mixins.scss";

.site-event {
  margin-bottom: 37px;
  .list-category {
    // padding: 0 10px;
    margin-bottom: 20px;
    border: 1px solid #ecedee;
    box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
      0px 4px 6px rgba(31, 41, 55, 0.08);
    border-radius: 8px;
    overflow: hidden;
    .ant-list-header {
      padding: 0;
    }
    .ant-list-items {
      padding: 0 12px;
    }
  }
  .catalog-style > a {
    color: rgba(0, 0, 0, 0.65);
  }
  .event-banner {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    padding: 50px 15px;
    text-align: center;
    position: relative;
    height: auto;
    max-height: 220px;
    background: url(../../images/blogcover.jpg);
  }
  h1 {
    position: relative;
    z-index: 99;
    font-size: 40px;
    color: #fff;
    margin: 0;
    font-weight: 500;
    padding: 0 0 20px;
    font-family: Tiempos, Arial, Helvetica, sans-serif;
    line-height: 45px;
    letter-spacing: 1.7px;
  }
  p.subtitle {
    position: relative;
    z-index: 99;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    margin: 0;
    line-height: 26px;
    font-weight: 100;
    font-family: harmonia, Arial, Helvetica, sans-serif;
  }
  .event-content {
    margin-top: 60px;
    display: block;
    position: relative;
    float: none;
    .left-title,
    .list-category {
      word-wrap: break-word;
      float: left;
      width: 100%;
      z-index: 100;
      h2 {
        font-weight: 500;
        color: #bf0000;
      }
    }
    .ant-list-item {
      @media screen and (max-width: $screen-sm) {
        width: 100%;
        float: left;
        background-color: white;
        margin-bottom: 10px;
      }
    }

    .event-item-image {
      position: relative;
      width: 35%;
      margin-right: 4%;
      display: block;
      float: left;
      @media screen and (max-width: $screen-sm) {
        width: 100%;
      }
    }
    .ant-list-item-meta {
      width: 60%;
      float: left;
      @media screen and (max-width: $screen-sm) {
        width: 100%;
      }
    }
    .child-event {
      h4 {
        width: 100%;
        display: block;
        margin: 0;
        height: auto;
        max-height: 64px;
        letter-spacing: 0.3px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        font-family: Tiempos, Arial, Helvetica, sans-serif;
        overflow: hidden;
      }
      .categories {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (max-width: $screen-sm) {
          white-space: initial;
        }
        a {
          display: block;
        }
        .custom-date,
        .author-link {
          color: #838383 !important;
          font-size: 14px;
          font-family: harmonia, Arial, Helvetica, sans-serif;
        }
      }
      .event-body {
        font-size: 16px;
        line-height: 24px;
        display: block;
        overflow: hidden;
        font-family: harmonia, Arial, Helvetica, sans-serif;
        p {
          margin-bottom: 0px;
        }
      }
    }
    .body {
      p {
        margin-top: 0;
        margin-bottom: 0px !important;
        margin-block-start: 0px;
        margin-block-end: 0px;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }
    @include maxWidth(767px) {
      margin-top: 10px;
    }
  }
}
