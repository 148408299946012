.ant-carousel {
  .slick-track {
    display: flex;
    height: 100%;
    .slick-slide {
      height: auto;

      .link,
      .cardBlock {
        height: 100% !important;
      }
    }
  }
}
.ant-carousel .slick-slide img {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}
.ant-carousel .slick-slide {
  .loading-custom {
    margin: 0% 0px !important;
    position: absolute;
    top: 50%;
  }
}
.widget-project.slick-slider {
  .slick-slide {
    height: 440px;
    > div {
      height: 100%;
    }
  }
  .slick-double,
  .slick-normal {
    display: flex !important;
    flex-direction: column;
    height: 100%;
  }
  .slick-double {
    > a {
      height: calc(100% / 2);
    }
  }
  .slick-prev,
  .slick-next {
    top: calc((100% - 36px) / 2);
  }
  .project-desc {
    font-size: 16px;
  }
  .card-link {
    background-color: rgb(232, 233, 234);
    position: relative;
    box-shadow: inset rgba(0, 0, 0, 0.4) 0 38px 20px -32px;
    border-radius: 8px;
    overflow: hidden;
    margin: 5px;
    height: 100%;
    .cardBlock {
      background: none;
      border: 0;
      margin: 0;
      height: 100%;
      .ant-card-body {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        color: white;
        text-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px;
        .card-head {
          color: white;
          order: 1;
          .head-subTitle {
            width: 100%;
            display: block;
            font-size: 16px;
            line-height: 1.1;
            padding: 0 0 8px;
          }
          .head-title {
            color: white;
            font-size: 18px;
            padding-bottom: 0;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            max-height: 126px;
            margin: 0;
            overflow: hidden;
          }
        }
        .flex-content {
          order: 2;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          display: flex;
          height: 100%;
          font-weight: bold;
          font-size: 28px;
          line-height: 1.14;
          flex: 1 1 0%;
        }
        .card-footer {
          flex-direction: row;
          -webkit-box-align: center;
          align-items: center;
          display: flex;
          order: 3;
          span {
            font-size: 18px;
            line-height: 20px;
          }
          i {
            margin-left: 10px;
            > svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .cartImage {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      transition: transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1;
        pointer-events: none;
      }
      img {
        object-fit: cover;
        object-position: center;
      }
    }
    &:hover {
      .cartImage {
        will-change: transform;
        transform: scale(1.1);
      }
    }
  }
}
.title-project-new {
  padding-top: 22px;
  text-transform: uppercase;
}
