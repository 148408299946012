@import "../../../reponsive.scss";

.ant-tabs-extra-content {
  @media screen and (max-width: $screen-md) {
    float: unset !important;
  }
}

.groupDetailSummary {
  display: flex;
  align-items: center;
  > img {
    max-width: 50px;
    margin-right: 1rem !important;
    margin-top: 0.5rem !important;
  }
}

.section-info {
  padding: 15px;
  background: #fff;
  border-radius: 4px;
}

.allProperties {
  border-radius: 0.25rem;
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  .ant-statistic-title {
    color: #fff;
  }
  .ant-statistic-content {
    color: #fff;
  }
}

.propertiesForSale {
  border-radius: 0.25rem;
  background: #2e5bff;
  background: -moz-linear-gradient(-45deg, #2e5bff 0%, #2effda 100%);
  background: -webkit-linear-gradient(-45deg, #2e5bff 0%, #2effda 100%);
  background: linear-gradient(135deg, #2e5bff 0%, #2effda 100%);
  .ant-statistic-title {
    color: #fff;
  }
  .ant-statistic-content {
    color: #fff;
  }
}

.propertiesForRent {
  border-radius: 0.25rem;
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  .ant-statistic-title {
    color: #fff;
  }
  .ant-statistic-content {
    color: #fff;
  }
}

.totalEarnings {
  border-radius: 0.25rem;
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  .ant-statistic-title {
    color: #fff;
  }
  .ant-statistic-content {
    color: #fff;
  }
}

.antd-pro-pages-dashboard-analysis-rankingList {
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
  li {
    zoom: 1;
    display: flex;
    align-items: center;
    margin-top: 16px;
    &::before {
      display: table;
      content: " ";
    }
    &::after {
      clear: both;
      height: 0;
      font-size: 0;
      visibility: hidden;
    }
    .antd-pro-pages-dashboard-analysis-rankingItemNumber {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: 1.5px;
      margin-right: 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      background-color: #f5f5f5;
      border-radius: 20px;
    }

    .antd-pro-pages-dashboard-analysis-rankingItemNumber.antd-pro-pages-dashboard-analysis-active {
      color: #fff;
      background-color: #314659;
    }
    .antd-pro-pages-dashboard-analysis-rankingItemTitle {
      flex: 1 1;
      margin-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
