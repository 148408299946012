@import "styles/_mixins.scss";

.eventItem {
  width: 100%;
  .itemLeft {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    & > img {
      height: 164px;
    }
  }
  .itemRight {
    padding-left: 1rem;
    .eventCreatedAt {
      margin: 0 4px;
    }
    .keyword {
      margin-right: 4px;
    }
  }
  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #060708;
  }
  @include maxWidth(767px) {
    &:not(.widgetSmall) {
      .itemRight {
        padding-left: 0;
        margin-top: 1rem;
      }
      .itemLeft {
        border: none;
      }
    }
  }
}
.widgetSmall {
  .itemLeft {
    & > img {
      height: 76px;
    }
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #060708;
  }
}

.eventSmallList {
  // padding: 0 10px;
  margin-bottom: 20px;
  border: 1px solid #ecedee;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  overflow: hidden;
  :global {
    .ant-list-header {
      padding: 0;
    }
    .ant-list-items {
      padding: 0 12px;
    }
  }
}
