@import '../../../reponsive.scss';

.statTitle {
  margin-top: 0;
  color: #374151 !important;
}
.statTitlePrice {
  @extend .statTitle;
  color: #bf0000 !important;
}
.statContainer {
  padding: 24px 0;
  @media only screen and (max-width: $screen-sm-max) {
    padding: 8px 0;
  }
}
.viewMoreBtn {
  background-color: transparent;
  border: none;
  outline: none;
  color: #bf0000;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 24px;
}
.realEstateDescContent {
  margin: 24px 0;
}
.renderList {
  :global {
    .ant-list-header {
      border: none;
      padding: 0;
    }
    .ant-list-item {
      list-style: disc;
    }
  }
}
.optionUtilItem {
  display: flex;
  .order {
    width: 24px;
    height: 24px;
    background: #bf0000;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
  }
  .content {
    margin-left: 12px;
    :global {
      .ant-typography {
        font-size: 14px;
      }
    }
    h3 {
      margin-top: 0 !important;
      margin-bottom: 4px;
      font-size: 16px;
      @media only screen and (max-width: $screen-sm-max) {
        font-size: 14px;
      }
    }
  }
}
