@import "../../reponsive.scss";

.sold{
  position: fixed;
  bottom: 0;
  width: 25%;
  @media screen and (max-width: $screen-xl-max) {
    width: 30%;
  }
  @media screen and (max-width: $screen-lg-max) {
    width: 40%;
  }
  @media screen and (max-width: $screen-md-max) {
    width: 52%;
  }
  @media screen and (max-width: $screen-sm-max) {
    width: 100%;
  }
  :global .ant-page-header {
    background: #e0c387e3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 16px;
    :global .ant-page-header-heading{
      margin: 10px 0 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      :global .ant-page-header-heading-title{
        display: inline-block;
        width: auto;
        font-size: 18px;
      }
      :global .ant-page-header-heading-extra{
        width: auto;
        padding-top: 0;
      }
    }
  }
  :global .ant-page-header-content{
    padding: 0;
    overflow: visible;
  }
  .cardListSold {
    &:global.cardList.slick-slider {
      .slick-prev, .slick-next{
        top: calc((50% - 56px));
      }
      .slick-list {
        .slick-slide {
          .ant-list {
            .ant-list-item{
              padding-top: 0;
              .ant-list-item-meta{
                .ant-list-item-meta-avatar{

                }
                .ant-list-item-meta-content{
                  .ant-list-item-meta-title{
                    font-weight: bold;
                    font-size: 18px;
                    @media screen and (max-width: $screen-xs-max) {
                      font-size: 14px;
                    }
                  }
                  .ant-list-item-meta-description{
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }
    }
    :global .customStyleCard{
      .customImage{
        &:before {
          padding-top: 50%;
        }
      }
      @media screen and (max-width: $screen-xs-max) {
        .ant-card-meta-title{
          > a{
            font-size: 14px !important;
          }
        }
        .ant-card-meta-description{
          font-size: 12px;
        }
      }
    }
  }
}