@import "styles/_mixins";
.specList {
  font-size: 1rem;
  @include flexbox(stretch, flex-start, row, wrap);
  margin-left: -1rem;
  .specItem {
    width: calc(50% - 1rem);
    list-style: none;
    @include flexbox(center);
    margin-left: 1rem;
    padding: 7px 0;
    margin-bottom: 17px;
  }
  .specItemIcon {
    min-width: 24px;
    height: 24px;
    @include flexbox(center);
  }
  .specItemLabel {
    margin-left: 8px;
  }
  .specItemLabelWrapper {
    width: 60%;
  }
  .specItemValue {
    width: 40%;
    font-weight: 600;
    color: #374151;
  }
  .specItemLabelWrapper {
    @include flexbox(center);
  }
  @include maxWidth(767px) {
    display: block;
    .specItem {
      width: unset;
      border-bottom: 1px solid rgba(48, 51, 58, 0.15);
      margin-bottom: 0;
      &:last-of-type {
        border-bottom: 0;
      }
    }
    .specItemValue {
      text-align: end;
    }
  }
}
