.listOptions {
  padding: 0 10px;
  margin: 0;
  list-style-type: none;
  li {
    float: left;
    margin-right: 10px;
  }
}

.customSlider{
  :global .ant-slider-mark{
    .ant-slider-mark-text:first-child{
      transform: translateX(0) !important;
      white-space: nowrap;
    }
    .ant-slider-mark-text:last-child{
      transform: translateX(-100%) !important;
      white-space: nowrap;
    }
  }
}

.listOptionsMobile {
  :global .ant-list-item{
    padding: 0 10px;
    .ant-list-item-meta{
      margin: 10px 0;
    }
    .ant-checkbox-group{
      display: flex;
      flex-wrap: wrap;
      .ant-checkbox-group-item{
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(100% / 2);
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    .ant-slider{
      margin-left: 20px;
      margin-right: 20px;
      width: 100%;
      .ant-slider-mark-text{
        transform: translateX(-100%) !important;
        white-space: nowrap;
      }
    }
  }
}