@import "../../reponsive.scss";
.bread-custom-container {
  padding-top: 10px;
  padding-bottom: 10px;
  .ant-breadcrumb a {
    color: #bf0000;
  }
}
.real-estate-container {
  background: white;
}
.real-estate {
  padding-top: 0px;
  padding-bottom: 15px;
  .home-map {
    color: #cf1322;
    font-size: 30px;
  }
  .section-wrap {
    margin: 0px;
    padding: 0px;
    padding-right: 15px;
    .ant-card {
      .cartTitle {
        text-align: left;
      }
      .ant-card-actions {
      }
    }
  }
  .ant-checkbox-checked {
    background-color: green;
    border-color: green;
  }
  .project_content {
    border-color: #ddd;
    padding-right: 20px;
    .ant-descriptions-item-label {
      color: black;
    }
    .ant-descriptions-item-content {
      background-color: white;
      color: #bf8100;
    }
    .ant-typography {
      margin-top: 10px;
    }
    .header_title {
      font-weight: bold;
      margin-top: 15px;
      .project_name {
        color: black;
        font-size: 22px;
      }
      .price {
        color: #ff9113;
        float: right;
        font-size: 20px;
      }
    }
    .ant_divider_custom {
      margin: 12px 0px;
      margin-bottom: 5px;
    }
    .create-time {
      color: #9a9a9a;
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      float: left;
      .i_clock_circle {
        margin-right: 5px;
      }
      .i_location {
        margin: 0 0px 0px 5px;
      }
      a {
        margin-left: 5px;
        color: green;
      }
    }
    .broker-price {
      height: 141px;
      margin-bottom: 20px;
      width: 100%;
      float: left;
      position: relative;
      border: 1px solid #e8e8e8;
      z-index: 1;
      .price-col,
      .broker-col {
        height: 100%;
      }
      .price-container {
        padding: 5px;
        height: 100%;
        margin-left: 0px;
        background: #ddd;
        .price-content {
          margin: 5px;
          margin-bottom: 15px;
          text-align: center;
          border-bottom: 1px solid #e6ba5c;
          .price-text {
            font-size: 26px;
            font-weight: 700;
            color: #bf0000;
          }
          .sold-out {
            border: 2px solid #bf0000;
            padding: 5px;
            color: #bf0000;
            border-radius: 10px;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
        .information {
          padding-bottom: 10px;
          .infor-content {
            text-align: center;
            .value {
              font-size: 20px;
              color: #bf8100;
              font-weight: bold;
            }
            .valuation {
              label {
                text-align: center;
              }
              .ant-checkbox-wrapper {
                margin-right: 10px;
              }
            }
          }
        }
      }
      .broker {
        height: 100%;
        background-color: #fff;
        margin-right: 0px;
        > .ant-row-flex {
          height: 100%;
          padding: 0 2rem;
          .avatar {
            display: block;
            margin-right: 10px;
            .image-cropper {
              position: relative;
              width: 50px;
              height: 50px;
              img {
                background: white;
                width: 100%;
                border-radius: 100%;
                height: 100%;
              }
            }
          }
        }
        .contact {
          display: flex;
          align-items: center;
          > a {
            height: 100%;
            > img {
              max-width: 50px;
              width: 100%;
            }
          }
        }
        .name-rate {
          .ant-typography {
            font-size: 20px;
            margin-bottom: 0 !important;
          }
          .ant-rate {
            margin-bottom: 10px;
          }
          .icon-verify {
            color: #408ff7;
          }
        }
        .name-role {
          color: #bf8100;
          margin-bottom: 0.5rem;
          font-size: 13px;
        }
        .ant-btn-primary {
          margin-right: 10px;
          background-color: #bd0000 !important;
          border-radius: 100px;
          height: 100%;
        }
        .ant-btn-danger {
          background-color: #bf8100;
          border-radius: 100px;
          height: 100%;
          border-color: transparent;
        }
        .zalo-img {
          padding-left: 10px;
        }
        .messenger-img {
          padding-left: 10px;
        }
      }
    }
    .description-real-estate {
      font-size: 14px;
      color: #374151;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 1em auto;
        img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          min-width: 50px;
        }
        > figcaption {
          display: table-caption;
          caption-side: bottom;
          color: #333;
          background-color: #f7f7f7;
          padding: 0.6em;
          font-size: 0.75em;
          outline-offset: -1px;
        }
      }
      ol,
      ul {
        padding-left: 30px;
      }
    }
    .sub-heading {
      margin-top: 0;
      color: #374151 !important;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
    .border-bottom {
      border-bottom: 1px solid #e0e0e0;
    }
    .description-real-estate.full-desc {
      -webkit-line-clamp: initial;
    }
    .gallery-container {
      .ant-tabs-nav {
        width: 100%;
        font-size: 18px;
        .ant-tabs-tab {
          width: 33.3333%;
          text-align: center;
          margin: 0px;
        }
        .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
          width: 33.33% !important;
        }
      }
      .project_image {
        margin-top: 6px;
        img {
          width: 100%;
          max-height: 650px;
          @media screen and (max-width: $screen-md) {
            height: 100%;
          }
        }
        .gallery-container {
          img {
            max-height: 650px;
          }
        }
        .image-gallery-thumbnail {
          img {
            max-height: 85px;
          }
        }
        .image-gallery-thumbnails-container {
          text-align: left;
        }
      }
      .anticon-loading {
        text-align: center;
        width: 100%;
        margin: 20% 0px;
      }
      .video-tab {
        width: 100%;
        float: left;
        .video-container {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          padding-top: 25px;
          height: 0;
          .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .basic_infor {
      padding-left: 20px;
      text-align: left;
      .li {
        display: inline-block;
        list-style-type: none;
        margin-bottom: 10px;
        .attr_name {
          text-transform: uppercase;
          color: #999;
          font-size: 14px;
        }
        .attr_value {
          color: black;
        }
      }
    }
    .utility {
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-bottom: 8px;
        margin-left: 0px;
      }
      .map-container {
        // background: white;
        // padding: 20px;
        // -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
        // -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
        // box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
        .ant-list-items {
          .ant-list-item-action {
            font-weight: bold;
            .ant-row {
              width: 150px;
              float: right;
              text-align: left;
              .text-rating {
                color: black;
                .text {
                  font-weight: bold;
                  font-size: 15px;
                }
                .rate-count {
                  font-weight: normal;
                  font-size: 14px;
                }
              }
            }
          }
          .ant-list-item-meta-title {
            font-size: 18px;
            color: #bf8100;
          }
          .ant-btn {
            background: #bf8100;
            color: white;
          }
        }
        .ant-list-items-srroll {
          max-height: 710px;
        }
        .custom_tab {
          width: 100%;
          float: left;
        }
        .badge {
          background-color: #52c41a;
          font-size: 22px;
          color: white;
        }
        /* infor window popup */
        .InforWindow {
          z-index: 1000;
          cursor: pointer;
          .leaflet-zoom-animated {
            position: absolute;
            bottom: 7px;
            left: -80px;
            text-align: center;
          }
          .leaflet-popup-content-wrapper {
            background: #fff;
            color: #333;
            box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
          }
          .leaflet-popup-content {
            .marker-popup {
              min-width: 190px;
              min-height: 70px;
              padding-top: 10px;
              padding-bottom: 17px;
              padding-left: 10px;
              padding-right: 12px;
              .image {
                padding: 5px;
                img {
                  float: left;
                }
              }
              b {
                margin-left: 10px;
                font-size: 12px;
              }
              span {
                margin-top: 5px;
                display: inline-block;
              }
            }
          }
          .leaflet-popup-tip-container {
            position: absolute;
            left: 46%;
            bottom: -6px;
            background: white;
            margin-left: -20px;
            overflow: hidden;
            pointer-events: none;
            width: 17px;
            height: 17px;
            padding: 1px;
            margin: -10px auto 0;
            transform: rotate(45deg);
          }
          .leaflet-popup-close-button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 4px 4px 0 0;
            border: none;
            width: 18px;
            height: 14px;
            font: 16px/14px Tahoma, Verdana, sans-serif;
            color: #c3c3c3;
            text-decoration: none;
            font-weight: 700;
          }
        }
        .leaflet-popup-close-button:focus .InforWindow {
          display: none;
        }
      }
      .card_container {
        .tab_content {
          .ant-list-bordered {
            border: none;
          }
          .ul {
            .ant-list-item {
              border-bottom: 0;
              padding: 0;
              margin-bottom: 1rem;
            }
            list-style-type: none;
            .list-hover:hover {
              background: #eeeeee;
              cursor: pointer;
            }
            .li {
              padding-bottom: 7px;
              .number {
                background-color: #eeeeee;
                color: black;
                padding: 0 8px;
                height: 23px;
                font-weight: bold;
                border-radius: 0px;
              }
              .text {
                margin-left: 10px;
              }
            }
            .li:last-child {
              padding-bottom: 0px;
            }
          }
        }
      }
      .ant-tabs .ant-tabs-left-bar {
        width: 30%;
      }
      .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
        text-align: left;
      }
      .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        border-radius: 0;
      }
      .full-map {
        height: 100%;
        z-index: 9999;
      }
      .image-map {
        width: 25px;
      }
      .image-unity {
        width: 30px !important;
        img {
          width: 30px !important;
        }
      }
    }
  }
  .estimate {
    background: white;
    -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
    padding: 0 15px 15px 15px;
    .ant-list-split .ant-list-item:last-child {
      border: 0px;
    }
    .widget_frontPageSection__5MkWT {
      .ant-list-split .ant-list-item:last-child {
        border: 0px;
      }
      .ant-typography {
        margin-bottom: 0px;
      }
    }
    .estimate-title {
      background-color: orange;
      text-align: center;
      padding: 10px 0px;
      color: white;
      margin: 10px -15px;
    }
    .ant-typography {
      margin-bottom: 10px;
    }
    .owner-price {
      text-align: center;
      h4 {
        margin-bottom: 5px;
        font-size: 20px;
      }
      .price {
        display: grid;
        .price-text {
          font-size: 26px;
          font-weight: bold;
          color: red;
        }
      }
      .valuation {
        display: grid;
        text-align: center;
        input {
          margin: 0 auto;
          width: 30px;
          height: 35px;
          color: green;
        }
      }
    }
    .price_estimate {
      text-align: center;
      font-weight: bold;
      margin-top: 25px;
      .text {
        margin-bottom: 0px;
      }
      .price_buy {
        color: green;
        font-size: 20px;
      }
      .price_rent {
        color: blue;
        font-size: 20px;
      }
    }
    .esimate_description {
      padding: 10px;
      color: #606060;
      text-align: left;
      margin-bottom: 10px;
      .hight-light {
        color: blue;
      }
    }
    .history_price {
      .title {
        font-size: 18px;
        color: black;
        margin-top: 25px;
        margin-bottom: 15px;
        text-transform: uppercase;
      }
      .price_incre {
        color: green;
        margin-left: 4px;
      }
      .price_decre {
        color: red;
        margin-left: 4px;
      }
    }
    .loan {
      .ant-typography {
        margin-bottom: 20px;
      }
      .price {
        padding-right: 15px;
      }
    }
    .project-owner {
      margin-bottom: 25px;
      padding-top: 15px;
      .ant-typography {
        padding-left: 15px;
        color: #be0101;
        margin: 10px -15px;
        margin-top: 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ededed;
      }
      .ant-descriptions-title {
        margin-bottom: 10px;
      }
      .ant-descriptions-row > th,
      .ant-descriptions-row > td {
        padding-bottom: 5px;
      }
      .ant-descriptions-item-content {
        .text {
          font-size: 15px;
          span {
            color: #bf8100;
          }
        }
      }
      a {
        color: #bf8100;
        font-size: 17px;
        margin-left: -5px;
      }
      .image-project {
        img {
          width: 150px;
        }
      }
    }
    .agency {
      .ant-typography {
        background: linear-gradient(to right, #bf8100, #e6ba5c);
        color: white;
        padding-top: 12px;
        padding-left: 10px;
        padding-bottom: 12px;
        margin-right: -15px;
        margin-left: -15px;
        margin-top: 15px;
      }
      .agency-card {
        margin-bottom: 7px;
        .contact {
          display: flex;
          height: 100%;
          margin-top: 10px;
          .message,
          .phone {
            height: auto;
            font-size: 13px;
          }
          .message {
            margin-right: 5px;
          }
          .phone {
            display: flex;
            border-color: #1890ff;
            color: #1890ff;
            height: 40px;
            i {
              font-size: 17px;
              vertical-align: middle;
              margin-right: 5px;
            }
            .number {
              display: grid;
              text-align: left;
            }
          }
        }
        .avata {
          padding: 10px;
          padding-top: 5px;
          img {
            width: 60px;
            height: 60px;
            border-radius: 60px;
          }
        }
        .name {
          font-weight: bold;
          color: green;
        }
        .detail {
          display: grid;
          margin-top: 10px;
        }
        .name-role {
          color: #bf8100;
          margin-bottom: 0.5rem;
          font-size: 13px;
        }
      }
    }
    .agency-contact {
      .ant-list-split {
        background-color: #bf8100;
        padding-left: 5px;
        .ant-list-item-meta-title {
          b {
            color: white;
          }
        }
        .ant-btn-danger,
        .ant-btn.phone {
          border-color: white;
          background-color: white !important;
          i {
            color: #40a9ff;
          }
        }
      }

      .ant-list-item-action-split {
        background-color: inherit !important;
      }
    }
    .agency-post {
      .avata {
        padding: 0px !important;
      }
    }
    .form-contact {
      margin-top: 20px;
      padding: 0px 7px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #f5f6f8;
      .ant-row {
        margin-bottom: 0px;
        .ant-form-item {
          margin-bottom: 10px;
          .ant-form-item-label {
            line-height: 20px;
          }
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
  .ant-checkbox-checked::after,
  .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid green;
    background-color: green;
  }
  .comments {
    .star_yellow {
      color: #ffb532;
    }
    .title {
      padding: 10px 0px;
      padding-left: 10px;
      background: #3399ff;
      color: white;
      margin: 0 -15px;
      margin-top: 15px;
      font-size: 18px;
    }
    .comments-content {
      .row-comment {
        margin-bottom: 5px;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 15px;
        .comment-item {
          .ant-comment-inner {
            padding: 7px 0px;
          }
          .ant-comment-content-author {
            display: grid;
            .ant-comment-content-author-name {
              font-size: 15px;
              .nick-name {
                margin-right: 10px;
                color: black;
                font-weight: bold;
              }
            }
            .ant-comment-content-author-time {
              color: #7e7575;
            }
          }
          .ant-comment-content-detail {
            margin-left: -40px;
            p {
              margin-bottom: 0px;
            }
          }

          .images-comment {
            .image-item {
              img {
                width: 100%;
                padding: 0 5px;
              }
            }
          }
        }
      }
    }
    .comment-form {
      .ant-form-item {
        margin-bottom: 10px;
        padding-right: 10px;
      }
    }
  }
  .article-list {
    .title {
      padding: 10px 0px;
      padding-left: 10px;
      background: #bf8100;
      color: white;
      margin: 0 -15px;
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 18px;
    }
  }
  .data-list-container {
    .title {
      background: linear-gradient(to right, #bf8100, #e6ba5c);
      color: white;
      padding-top: 12px;
      padding-left: 10px;
      padding-bottom: 12px;
      margin-right: -15px;
      margin-left: -15px;
    }
    .data-item.real-estates {
      padding: 5px 10px 5px;
    }
    .pagination-container {
      margin-bottom: 10px;
    }
  }
  .project-area {
    margin-bottom: 15px;
    .ant-typography {
      margin-top: 15px;
      padding: 10px 0px;
      padding-left: 10px;
      color: #be0101;
      margin: 0 -15px;
      font-size: 18px;
      border-bottom: 1px solid #ededed;
    }
    a {
      color: inherit;
      display: grid;
      font-size: 15px;
    }
    .ant-list-split .ant-list-item {
      border-bottom: 0;
    }
    .area {
      margin: 0 -15px;
      padding-left: 15px;
    }
  }
  .interest-rate {
    display: block;
    .price {
      font-size: 17px;
    }
    .red {
      color: #bf0000;
    }
    .blue {
      color: #1890ff;
    }
    form {
      width: 100%;
      height: 100%;
      float: left;
      background-color: #f3f4f7;
      .estimate-content {
        padding: 15px;
      }
      .calculate-content {
        padding: 15px;
        background-color: white;
      }
      .input-range {
        padding: 10px;
        border-bottom: 0px;
      }
      .radio-group {
        width: 100%;
        padding-top: 20px;
        .ant-radio-wrapper {
          margin-bottom: 7px;
        }
      }
      .chart {
        width: 100%;
        padding: 10px;
        float: left;
        border-top: 0px;
        margin-top: -8px;
        .infor-loan {
          .first-row {
            padding-top: 20px;
            text-align: left;
            .price-content {
              margin-bottom: 10px;
            }
            .price {
              display: grid;
            }
          }
        }
        .note {
          margin-top: 15px;
          display: grid;
        }

        .chart-estimate {
          .title {
            font-size: 18px;
            color: black;
          }
          .chart-custom {
            width: 100%;
            .chartjs-render-monitor,
            canvas {
              width: 100% !important;
            }
          }
          .price-estimate {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
          .text {
            font-size: 25px;
            font-weight: bold;
          }
        }
      }
      .first-month-rate {
        width: 100%;
        float: left;
        padding-top: 15px;
        padding-left: 20px;
        .label {
          float: left;
          margin-top: 7px;
          font-size: 17px;
        }
        .price {
          float: right;
          padding-right: 15px;
        }
        .price-paid {
          font-size: 25px;
          text-align: left;
          color: #30333a;
        }
      }
    }
  }
}
@media screen and (max-width: $screen-md) {
  .section-wrap.realState {
    padding: 0 20px;
  }
  .real-estate {
    padding-right: 10px;
  }
  .real-estate-container {
    .project_content {
      padding-right: 0px;
      .broker-price {
        height: inherit !important;
      }
    }
    .estimate {
      margin-top: 10px;
      .chart-custom {
        width: 80% !important;
      }
    }
    .interest-rate {
      .first-month-rate {
        padding-top: 0px !important;
        padding-left: 10px !important;
        .price-paid {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  .real-estate-container {
    .project-owner {
      .image-project {
        img {
          width: 100% !important;
          height: 100%;
        }
      }
      .description {
        padding-left: 10px;
      }
    }
  }

  .broker-price {
    .contact {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
