@import "../../../reponsive.scss";
.sidebarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-color: #e6ecf9a1;
  border-right: 1px solid #e6ecf9a1;
  transition: left 0.5s ease;
  @media screen and (max-width: $screen-lg-max) {
    background-color: #e6ecf9;
    width: 30%;
    left: -30%;
  }
  @media screen and (max-width: $screen-md) {
    width: 50%;
    left: -50%;
  }
  @media screen and (max-width: $screen-xs) {
    width: 100%;
    left: -100%;
  }
}
.openMenu {
  left: 0%;
}
.menuOpenIcon {
  background-color: #bf8100;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: none;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  @media screen and (max-width: $screen-lg-max) {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1;
    margin-top: 10px;
  }
}
.menuCloseIcon {
  background-color: #bf8100;
  height: 35px;
  width: 35px;
  line-height: 35px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: none;
  @media screen and (max-width: $screen-lg-max) {
    display: block;
  }
}

.menuList {
  & > li {
    list-style: none;
    & > a {
      font-size: 16px;
      padding: 12px 0px 12px 60px;
      width: 100%;
      display: block;
      transition: all linear 0.3s;
      border-bottom: 1px solid #0a547a14;
      cursor: pointer;
      &:hover {
        background-color: #0a547a17;
      }
    }
  }
}
.logoWrapper {
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  background-color: #fff;
  height: 48px;
}
.logo {
  width: 150px;
  max-width: 100%;
  height: 100%;
}
.avatarWapper {
  text-align: center;
  & > img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 4px solid #fff;
  }
}
.active {
  color: #BF0000;
  background-color: #0a547a17;
}
