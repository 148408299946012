.banner {
  height: 200px;
  .ant-upload {
    display: flex;
    width: 100%;
    height: 200px;
    > span {
      display: flex !important;
      height: 200px;
      >img {
        object-fit: cover;
      }
    }
  }
}
