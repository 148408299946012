.countdown {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__item {
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-left: 15px;
    height: 100px;
    font-weight: normal;
    span {
      color: white;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  &__item::after {
    content: ":";
    position: absolute;
    align-self: center;
    top: 10px;
    left: 100%;
  }
  &__item:last-child::after {
    content: "";
  }

  &__item--counting:after {
    margin-left: 55px;
  }
}
