.rowInput {
  margin-bottom: unset !important;
}

.customInpNum {
  width: 100% !important;
}

.textarea {
  background: #fff;
}

.sectionInfo {
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  h4 {
    background: #bf8100;
    padding: 10px;
    margin: -15px -15px 0 -15px;
    color: white;
    border-radius: 4px 4px 0 0;
    font-size: 15px;
    font-weight: bold;
  }
}

.selectedInternals {
  padding-right: 5px;
}
.realEstateFormWrapper {
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  padding: 24px;
  border-radius: 24px;
}
