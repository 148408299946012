@import "styles/_mixins.scss";

.projectWidget {
  margin-bottom: 20px;
  border: 1px solid #ecedee;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  overflow: hidden;
  :global {
    .ant-list-header {
      padding: 0;
    }
    .ant-list-items {
      padding: 0 12px;
    }
  }
  .itemLeft {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
  }
  .itemRight {
    padding-left: 8px;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #060708;
      @include textClamp;
    }
    .acreage {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #374151;
    }
    .icon {
      margin-right: 4px;
    }
    .broker {
      color: #BF0000;
    }
  }
}
