@import "../../../reponsive";
.newRealEstateList {
  margin-top: 0 !important;
}
.frontPageSection {
  @media only screen and (max-width: $screen-md-max) {
    padding: 1rem 0;
  }
  .widgetTitle {
    .widgetSubtitle {
      display: block;
      font-size: 16px;
      color: #919191;
      font-weight: 400;
    }
  }
  .categoriesWrap {
    width: 100%;
    margin: 0 auto;
    .categoryCover {
      border-radius: 4px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      position: relative;
      &:before {
        content: "";
        display: block;
        padding-top: 125%;
      }
      .infoBox {
        position: absolute;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1;
        opacity: 0;
        padding: 15px;
      }
      a {
        background: rgba(0, 0, 0, 0.5);
        color: white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
      }
      .categoryIcon {
        margin: 0 auto;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 3px solid white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        @media only screen and (min-width: 480px) {
          width: 150px;
          height: 150px;
          svg {
            width: 80px;
            height: 80px;
            max-width: 80%;
            max-height: 80%;
            min-height: 20px;
            min-width: 20px;
          }
        }
      }
      .categoryText {
        position: absolute;
        bottom: 0;
        color: white;
        width: 100%;
        padding: 0 1.5rem;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0)
        );
        .categoryTitle {
          margin-top: 0.75rem;
          font-size: 1.5rem;
          margin-bottom: 0.25rem;
          font-weight: bold;
        }
      }
      .categoryCount {
        display: block;
        width: 36px;
        height: 36px;
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 18px;
        line-height: 36px;
        background: white;
        color: black;
        border-radius: 50%;
      }
    }
  }
  .widgetTitlePage {
    display: flex;
    justify-content: space-between;
    margin: 0;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    @media only screen and (max-width: $screen-sm-max) {
      flex-direction: column;
      align-items: baseline;
      :global .ant-typography {
        margin: 0;
      }
    }
    .widgetSelectFilter {
      @media only screen and (max-width: $screen-sm-max) {
        width: 100%;
        margin-bottom: 10px;
        :global .ant-select {
          width: 100%;
        }
      }
    }
  }
  .listPage {
    display: inline-block;
    width: 100%;
    .listPageItem {
      display: flex;
      padding: 0;
      :global .ant-card {
        width: 100%;
        :global .ant-card-head {
          padding: 0 12px;
          border-color: rgba(232, 232, 232, 0.39);
          :global .ant-card-head-title {
            white-space: normal;
            padding: 12px 0;
          }
        }
        :global .ant-card-body {
          padding: 12px;
          display: flex;
          align-items: flex-start;
          @media only screen and (max-width: $screen-sm-max) {
            padding-bottom: 0;
            flex-direction: column;
            .ant-list-item-meta {
              margin-top: 16px;
            }
          }
        }
      }
      &:global.small {
        .ant-card-body {
          @media only screen and (max-width: $screen-sm-max) {
            flex-direction: row;
          }
        }
      }
      .listPageImage {
        position: relative;
        width: 25%;
        margin-right: 4%;
        display: block;
        float: left;
        @media only screen and (max-width: $screen-sm-max) {
          width: 100%;
          margin: 0;
        }
        :global .imageResize {
          width: 100%;
          padding: 65px 0;
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: relative;
          border-radius: 5px;
          .card-tag-left {
            position: absolute;
            top: 5px;
            left: 5px;
          }
          @media only screen and (max-width: $screen-sm-max) {
            padding: 65px 0;
          }
        }
        &:global.small {
          @media only screen and (max-width: $screen-sm-max) {
            width: 25%;
            margin-right: 4%;
          }
          .imageResize {
            padding: 50px 0;
            /*@media only screen and (max-width: $screen-sm-max) {
              padding: 150px 0;
            }*/
          }
        }
      }
      .listPageContent {
        flex: 1;
        @media only screen and (max-width: $screen-sm-max) {
          width: 100%;
          margin: 5px 0;
          &:global.small {
            > a {
              font-size: 14px !important;
            }
            > div {
              > span {
                font-size: 12px;
              }
            }
          }
        }
        > a {
          color: #333;
          &:hover {
            color: #cc2621;
          }
        }
      }
      :global .cartTitle {
        font-size: 20px;
        font-weight: bold;
        display: block;
        &:global.small {
          font-size: 14px;
          margin: 0;
        }
      }
      :global .ant-list-item-meta-title {
        margin: 0;
      }
      :global .cartDes {
        margin: 0;
      }
    }
    :global .ant-list-pagination {
      margin-bottom: 24px;
      margin-right: 10px;
    }
    :global .ant-list-header {
      padding-top: 0;
      padding-bottom: 0;
      > * {
        > span {
          color: white;
        }
      }
    }
    .smallTitle {
      margin: 0;
      padding: 0 10px;
      font-size: 20px;
    }
  }
}
.smallWiget {
  border: 1px solid #ecedee;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
}
.sideBarBanner {
  margin-bottom: 24px;
}
