.wrapper {
  & .blockPriceContent {
    width: 100%;
    color: #fff;
    & .container {
      max-width: 100%;
    }
  }
}
.blockHead {
  height: 120px;
  text-align: center;
  padding-top: 15px;
  & > p {
    font-size: 18px;
  }
}
.heading {
  text-transform: uppercase;
}
.subHeading {
  color: black;
}

.tableFixed {
  table-layout: fixed;
  position: sticky;
  top: 0;
  background-color: #222;
  z-index: 2;
}
.apartmentTableWrapper {
  background-color: #222;
}
.appartmentTable {
  @extend .tableFixed;
  border: 0.6px solid #d0d3d9 !important;
}
.apartmentCellComon {
  border: 1px solid #d0d3d9;
  min-width: 99px;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}
.apartmentCellContent {
  @extend .apartmentCellComon;
  height: 103px;
  color: #e8e8e8;
  font-size: 14px;
  font-weight: 500;
}
.boxAppartmentContainer {
  width: 100%;
  overflow-y: auto;
  border: 1px solid #ddd;
}
.apartmentIcon {
  & > svg {
    height: 28px;
    width: 28px;
  }
}
.contentPopover {
  display: flex;
  align-items: center;
  & > span {
    margin-right: 8px;
  }
  margin-bottom: 1rem;
}
.firstColumn {
  position: sticky;
  top: 37px;
  left: 0;
  background-color: #222;
  z-index: 3;
}
.headerFirstColumn {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #222;
  z-index: 2;
}
.floorInfo {
  display: flex;
  flex-direction: column;
  & > span {
    &:not(:nth-child(1)) {
      color: #bf8100;
    }
  }
}
.apartmentHover {
  background-color: #5e5e5e;
}
.titlePopover {
  margin-top: 1rem;
}
.cartIconWrapper {
  cursor: pointer;
}
.apartmentPopoverWrapper {
  width: 300px;
  & > div {
    & > div {
      &:nth-child(2) {
        & > div {
          & > div {
            &:nth-child(1) {
              background-color: #BF0000;
              color: #fff;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
/*----------  NotePopover  ----------*/
.tableNote {
  background-color: #fff;
  & tr {
    & th {
      border: 1px solid black;
      padding: 6px;
      & > span {
        &:nth-child(1) {
          margin-right: 8px;
          & > i {
            font-size: 19px;
          }
        }
      }
    }
    & td {
      border: 1px solid black;
      padding: 6px;
    }
  }
}
.notePopoverContainer {
  background-color: #fff;
}

/*----------  Project list  ----------*/
.projectItem {
  height: 45px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  & > div {
    height: 100%;
    width: 100%;
    flex: 1;
    margin-right: 8px;
  }
  & img {
    height: 100%;
    max-width: 100%;
  }
  & p {
    padding: 5px;
    margin-bottom: 0;
  }
}
.activeProjectItem {
  background-color: #bf0000;
}

/*----------  Building list  ----------*/
.boxBuilding {
  width: 100%;
  padding: 8px;
  border: 0.6px solid #dfdfdf;
  background-color: #222;
}

.buttonSlider {
  width: 24px;
  height: 24px;
  border: 1px solid #d0d3d9;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonSliderWrapper {
  display: flex;
  justify-content: flex-end;
}
.building {
  width: 90% !important;
  height: 28px;
  border: 0.6px solid #d0d3d9;
  color: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    background-color: #bf0000;
  }
}
.buildingActive {
  background-color: #bf0000;
}
.slider {
  background-color: transparent;
  & > div {
    & > div {
      display: flex;
      justify-content: center;
    }
  }
}
.attributes {
  font-size: 11px;
}
.apartmentPrice {
  margin-bottom: 8px;
}
.shoppingCartIcon {
  font-size: 25px;
  color: #fff;
}
.apartmentCode {
  & > p {
    margin-bottom: 0;
    margin-left: 8px;
    &:nth-child(2) {
      font-size: 18px;
    }
  }
}
.apartmentPopoverTitle {
  padding: 8px 16px;
}
.apartmentAttributesRow {
  border-bottom: 1px dashed #d6d6d6;
  margin-bottom: 1rem;
  & > span {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 0.5rem;
  }
}
.apartmentPopoverPrice {
  & > p {
    margin-bottom: 0;
    &:nth-child(2) {
      font-size: 18px;
    }
  }
}
.divider {
  height: 2.9rem;
  background-color: #fff;
  margin: 0;
}
.attributeSuffix {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 4px;
}
