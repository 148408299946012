.marker {
  &__detail {
    height: fit-content;
    padding: 25px 10px 10px 10px;
    background-color: white;
    position: absolute;
    min-width: 200px;
    z-index: 1;
    bottom: 15px;
    left: -100px;
    font-size: 12px;

    @media screen and (min-width: 600px) {
      min-width: 400px;
      display: flex;
      justify-content: space-between;
      top: 40px;
      left: -150px;
      font-size: 14px;
    }

    &-close {
      top: 5px;
      right: 10px;
      position: absolute;
      border: 0;
      color: black;
      padding: 3px;
      cursor: pointer;
    }

    &:before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
      border-bottom: none;
      width: 10px;
      height: 10px;
      bottom: -10px;
      left: 105px;
      content: "";
      position: absolute;

      @media screen and (min-width: 600px) {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        border-top: none;
        top: -10px;
        left: 165px;
      }
    }

    &-top {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &-content {
      padding-left: 0;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 600px) {
        padding-left: 1rem;
      }
    }

    &-address {
      color: gray;
      margin-top: 0.5rem;
    }

    &-price {
      color: black;
      font-weight: bold;
      text-align: right;
    }

    &-image {
      width: 100%;
      height: 100px;
      margin-bottom: 5px;

      @media screen and (min-width: 600px) {
        width: 150px;
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
