@import "../../reponsive";

.siteFooter {
  padding: 15px 0px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url(../../images/bg-footer.jpeg);
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
  margin-top: auto;
  .footerWidgetArea {
    .widgetFooter {
      color: #ffffff;
      padding:0px 10px;
      max-width: 1366px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      .widgetFooterTop{
        display: inline-block;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #9999;
        :global .ant-list-item-meta-title{
          color: #ffffff;
          line-height: normal;
          > a {
            color: #ffffff;
          }
        }
        :global .ant-list-item-meta-description {
          color: #ffffff;
          > b {
            color: orange;
            font-size: 16px;
          }
        }
        :global .ant-list-split .ant-list-item{
          margin-bottom: 0;
          &:last-child{
            border: 0;
          }
        }
      }
      .widgetFooterContent{
        display: inline-block;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #9999;
        .social{
          font-size: 42px;
          color: white;
          i {
            margin-right: 20px;
          }
        }
        :global .ant-col{
          &.ant-col-lg-4 {
            width: calc(100% / 5);
          }

          > .img-logo {
             padding-top: 20px!important;
             img{
               width: 200px;display: inline-block;
               @media only screen and (max-width: $screen-xs-max) {
                 width: 100%;
               }
             }
          }
          > .ant-list-item {
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            .logo-bct { margin: 10px 0px; border-radius: 5px;}
            span.address{
              margin: 10px 0px;font-size: 13px;
              @media only screen and (max-width: $screen-xs-max) {
                text-align: center;
              }
            }
            span { color: #ececec; display: inline-block;font-size: 13px;}
            &:last-child{
              border-bottom: 0;
            }
            .ant-list {
              background: transparent;
              color: white;
              font-size: 13px;
              .ant-list-item{
                border-bottom: 0;
                padding: 5px 0px;
                justify-content: start;
                > a,> span{
                  color: #ececec;
                  &:hover {
                    color: #cc2621;
                  }
                }
              }
              .ant-list-header{
                border-bottom: 0;
              }
              .ant-spin-container {
                ul {
                  //li:before {
                  //  content: "\2022";
                  //  color: rgb(191, 129, 0);
                  //  width: 1em;
                  //  margin-left: -1em;
                  //  font-size: 14px;
                  //  font-weight: bold;
                  //}
                  .ant-list-item{
                    .ant-list-item-meta {
                      flex: 0;
                      margin: 0;
                      .ant-list-item-meta-avatar{
                        img {
                          border-radius: 5px;
                        }
                      }
                    }
                    > a{
                      flex: 1 1 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      [id*=social] {
        float: none;
        li.menuItem {
          margin: 0 2px;
          width: 2.75rem !important;
          height: 2.75rem !important;
          border: 2px solid currentColor;
          border-radius: 50%;
          display: inline-block;
          a {
            color: #ffffff;
            font-size: 27px;
          }
        }
      }
    }
  }
  .footerTextArea{
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    .siteInfo {
      color: #adadb2;
      font-size: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: $screen-xs-max) {
        flex-direction: column;
      }
      > * {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
      }
      .footerMenu {
        font-size: 0;
        li {
          font-size: 0.875rem;
          display: inline-block;
          &:after {
            content: '\2022';
            margin: 0 9px;
          }
          &:last-child:after{
            content: '';
          }
        }
      }
    }
    .themeInfo {
      color: #706c6c;
    }
  }
}
