@import 'styles/_mixins';
.brokerContainer {
  display: flex;
  padding-bottom: 28px;
  border-bottom: 1px solid #e0e0e0;
}
.btn {
  border-radius: 15px;
  color: white;
  border: 0;
}
.phoneNumberBtn {
  background-color: #bf0000;
  @extend .btn;
  &:hover {
    color: white !important;
    background-color: #bf0000 !important;
  }
  &:focus {
    color: white !important;
    background-color: #bf0000 !important;
  }
}
.inboxBuyerBtn {
  margin-left: 1rem;
  @extend .btn;
  background-color: #e2a329;
  &:hover,
  &:focus {
    color: white;
    background-color: #e2a329;
  }
  @include maxWidth(472px) {
    margin-left: 0;
    margin-top: 0.5rem;
    width: 60%;
  }
}
.brokerInfo {
  margin-left: 24px;
}
.brokerHeading {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
}
