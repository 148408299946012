.headingWrapper {
  padding: 16px 12px;
  & > h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin: 0;
  }
}
