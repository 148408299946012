.heading {
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}
.subheading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 4px;
  color: white;
  margin-bottom: 24px;
}
