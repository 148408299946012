@import "../../reponsive.scss";
.wrapper {
  padding: 50px 50px 63px 50px;
  background: white;
  min-height: 100vh;
  @media screen and (max-width: $screen-lg-max) {
    padding: 65px 10px 20px 10px;
  }
}
.container {
  background-color: #fff;
  padding: 0 0.75rem;
}
.pageContentTitle {
  color: "black";
}

.swalTitle {
  font-size: 1rem;
}
