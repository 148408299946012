@import "../../reponsive";

.wrapper {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 102px;
  min-height: 20vh;
}
.cartContainer {
  padding: 0 10px;
}
.cartTitle {
  margin-bottom: 20px;
  font-weight: 600;
  color: #000;
  font-size: 24px;
}
.cartImgWrapper {
  width: 100%;
  img {
    max-width: 100%;
  }
}
.cartInfoTitle {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
}

.cartInfoPrice {
  @extend .cartInfoTitle;
  margin-bottom: 0;
}
.unit {
  font-size: 14px;
  color: #979797;
  padding-left: 5px;
  font-weight: 700;
}
.cartDevider {
  margin: 18px 0;
}

/*----------  BuyerForm  ----------*/
.title {
  font-weight: 600;
  color: black;
  font-size: 1rem;
}
.buyerTypeGroup {
  display: flex;
}
.buyerTypeItem {
  width: 50%;
}
.buyerInfoTop {
  margin-top: 1rem;
}
.datePicker {
  width: 100%;
}
.uploadBanner {
  width: 100%;
  height: 120px;
  & > div {
    height: 100% !important;
    width: 100% !important;
    & > span {
      height: 120px !important;
      & > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

/*----------  cartPay  ----------*/
.cartPayCard {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  @media only screen and (max-width: $screen-md-max) {
    margin-top: 2rem;
  }
}
.cartPayTemp {
  font-size: 16px;
  font-weight: 700;
  & > div {
    &:nth-child(2) {
      color: black;
    }
  }
}
.cartPayTotal {
  @extend .cartPayTemp;
  & > div {
    &:nth-child(2) {
      color: #bf8100;
      font-size: 1.25rem;
    }
  }
}
.bookingSuccessImgWrapper {
  text-align: center;
  & > img {
    width: 50%;
    object-fit: cover;
  }
}

/*=============================================
=            Transaction            =
=============================================*/
.formItem {
  & > div {
    &:nth-child(1) {
      text-align: start;
    }
  }
  & > div {
    & > label {
      &::before {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 0;
      }
    }
  }
}
.paymentInfo {
  box-shadow: 0px 0px 0.8px rgba(3, 3, 3, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  padding: 1rem;
  margin-bottom: 1rem;
}
.headingPayment {
  font-weight: bold;
  color: black;
  font-size: 18px;
}
.countDown {
  font-weight: bold;
  font-size: 20px;
}
.backToHome {
  font-size: 18px;
  &:hover {
    text-decoration: underline;
  }
}
.titleAddress {
  margin-top: 1rem;
}
.buttonGroupWrapper {
  text-align: end;
}
.confirmationButton {
  margin-left: 1rem;
}
.createBuyerForm {
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  .title {
    margin: 1rem 0;
  }
  .customerHeading {
    margin-top: 0;
  }
}
.submitButton {
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  height: 48px;
  background: #21B21E;
  border: 0;
  &:hover {
    background: #21B21E;
  }
}
.submitButtonWrapper {
  text-align: center;
}
