.rowInput {
  margin-bottom: unset !important;
}

.customInpNum {
  width: 100% !important;
}

.textarea {
  background: #fff;
}

.sectionInfo {
  padding: 15px;
  background: #fff;
  border-radius: 4px;
}
