.project {
  &-banner {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    padding: 50px 15px;
    text-align: center;
    position: relative;
    height: auto;
    max-height: 220px;
    background: url(../../../images/blogcover.jpg);
    h1 {
      position: relative;
      z-index: 99;
      font-size: 40px;
      color: #fff;
      margin: 0;
      font-weight: 500;
      padding: 0 0 20px;
      font-family: Tiempos, Arial, Helvetica, sans-serif;
      line-height: 45px;
      letter-spacing: 1.7px;
    }
  }
  &-banner-subtitle {
    position: relative;
    z-index: 99;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    margin: 0;
    line-height: 26px;
    font-weight: 100;
    font-family: harmonia, Arial, Helvetica, sans-serif;
  }
  &-content {
    margin-top: 40px;
  }
  &-content-left-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-content-left-header-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
}
