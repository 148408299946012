@import "../../../reponsive.scss";

.investorWrapper {
  margin-bottom: 40px;
}
.headingWrapper {
  text-align: center;
}
.investorList {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
  overflow-x: auto;
}
.investorItem {
  width: calc(20% - 32px);
  height: 230px;
  border: 1px solid #ecedee;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  overflow: hidden;
  margin-left: 32px;
  margin-bottom: 32px;
  padding: 16px;
  background-color: white;
  & > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
@media screen and (max-width: $screen-md-max) {
  .investorWrapper {
    margin-top: 40px;
  }
  .investorList {
    margin-left: -12px;
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .investorItem {
    width: 100%;
    flex: 0 0 80%;
    scroll-snap-align: center;
    margin-left: 12px;
  }
}
