.cartBlock {
  .cartTitle{
    color: #bf0000;
    font-size: 18px;
    font-weight:bold;
  }
  .cartDes{
    font-size: 14px;
    p {
      max-height: 65px;
      height: 65px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
  }
  .cartImage {
    position: relative;
    background-position: center;
    background-size: cover;
    &:before{
      content: "";
      display: block;
      padding-top: 75%;
    }
    .card-tag-left{
      position: absolute;
      left: 10px;
      top: 10px;
      margin: 0;
    }
    .card-tag-right{
      position: absolute;
      right: 10px;
      top: 10px;
      margin: 0;
    }
  }
  .cartPrice{
    position: absolute;
    bottom: 15px;
    left: 0;
    padding: 5px 7px;
    font-size: 15px;
    background-color: rgba(80, 80, 80, 0.72);
    color: white;
  }
  :global .ant-card-meta-title{
    white-space: normal;
    color: unset;
  }
}