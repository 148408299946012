.orderInfoRow {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px dashed #e4e4e4;
}
.orderInfoLabel {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  min-width: 285px;
}
.apartmentInfoWrapper {
  position: sticky;
  top: 0;
  z-index: 2;
}
.heading {
  color: black !important;
}
.subHeading {
  color: black !important;
}
