.aritcle-detail-container {
  background-color: white;
  min-height: 600px;
}
.aritcle {
  .aritcle-title {
    .blog-banner {
      margin-bottom: 15px;
      .updater {
        font-size: 14px;
      }
    }
  }
  .article-content {
    padding: 0px;
    .article-body {
      img {
        width: 100%;
        height: 100%;
      }
      .hs-featured-image-wrapper {
        margin-bottom: 20px;
        width: 100%;
        float: left;
        img{
          width: 100%;
        }
      }
    }
  }
}
