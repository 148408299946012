@import "../../reponsive.scss";

.card-column {
  .search-container {
    .ant-typography {
      float: left;
    }
    .select {
      min-width: 200px;
      float: right;
    }
    margin-bottom: 20px;
  }
  .pagination-container {
      text-align: center;
      padding: 40px 0px;
      width: 100%;
  }
  .card-column-re {
    margin-bottom: 20px;
    .card-item {
      border-radius: 10px;
      margin: 5px;
      height: 100%;
      .cartItem {
        border-radius: 10px;
      }
      .ant-typography {
        .icon {margin-right: 5px;}
      }
      .ant-card-meta {
        .ant-card-meta-title {
          .cartTitle {
            color: #bd0000;
          }
          span {
            color: #BF8100;
          }
        }
      }
    }
  }
  .no-result {
    text-align: center;
    font-size: 16px;
    width: 100%;
  }
}


@media screen and (max-width: $screen-md) {
    .card-collumn .search-container .select {
      width: 100%;
    }

    .broker-container .body {
        background: white;
        padding-left: 20px;
        padding: 20px;
    }
}
