@import "../../reponsive.scss";

.real-estate-push {
  /* item real estate */
  .data-item.loading {
    border: 0px !important;
  }
  .data-item.real-estates.top {
    margin-bottom: 15px;
  }
  .data-item.real-estates {
    border-right: 1px solid #ededed;
    border-left: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    padding-bottom: 10px;
    width: 100%;
    float: left;
    .image-loading {
      .ant-skeleton-paragraph {
        li {
          width: 90% !important;
          height: 100px;
        }
      }
    }
    .image {
      img {
        width: 100%;
        height: 100px;
      }
    }
    .image.real-estates {
      img {
        width: 100%;
      }
      position: relative;
      .card-tag-left {
        position: absolute;
        left: 5px;
        margin-top: 5px;
      }
    }
    .description {
      padding-left: 15px;
      .title-item {
        float: left;
        padding-top: 5px;
        font-size: 16px;
        color: black;
        font-weight: bold;
      }
      .date {
        font-size: 14px;
        color: grey;
        display: inline-block;
        width: 100%;
        .user_name {
          font-weight: bold;
          color: #cc9036;
        }
        .icon {
          margin-right: 5px;
        }
      }
    }
    .title-item {
      font-weight: normal;
    }
    .location {
      font-size: 13px;
      padding: 3px 0px;
      .icon {
        margin-right: 5px;
      }
    }
    .price-acreage {
      width: 100%;
      padding: 5px 0px;
      font-size: 13px;
      text-align: left;
      float: left;
      .price,
      .acreage {
        font-weight: bold;
        font-size: 20px;
      }
      .price {
        color: #bf0000;
      }
      .acreage {
        color: #cc9036;
      }
    }
  }

  /* Package */
  .section-content {
    background-color: white;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    .text-title,
    .text-service {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      width: 100%;
      float: left;
    }

    .package-section {
      background-color: #f0f2f5;
      padding: 20px;
      .ant-skeleton-paragraph {
        li {
          height: 200px;
        }
      }
    }
    .package-content {
      border-bottom: 1px solid #cdcdcdcd;
      .text-service {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        float: left;
      }
      .ant-radio {
        float: left;
        margin-right: 9px;
      }
      .radio-custom {
        width: 100%;
        float: left;
        height: max-content !important;
        line-height: 22px!important;
        margin-bottom: 15px;
        .title {
          float: left;
          font-size: 16px;
          line-height: 18px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .package-description {
          z-index: 100;
          padding-left: 25px;
          float: left;
          width: 100%;
          white-space: normal;
          word-spacing: 2px;
          line-height: 20px;
          width: -webkit-fill-available;
        }
      }
    }
    .package_setting {
      border-bottom: 1px solid #cdcdcdcd;
      padding-bottom: 20px;
      margin-bottom: 20px;
      .ant-input-group-wrapper {
        width: 120px;
      }
      .ant-form-item-label {
        font-weight: bold;
      }
      .ant-select {
        width: 30%;
      }
    }
    .payments {
      .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        float: left;
      }
      .ant-radio-group {
        display: initial;
      }
      .radio-payment {
        width: 100%;
        float: left;
        color: black;
        display: flex;
        background-color: white;
        padding: 10px 0px;
        padding-left: 10px;
        .ant-radio {
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .description {
          align-items: center;
          justify-content: center;
          display: flex;
          font-weight: bold;
        }
        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
      }
    }
    .btn-submit-payment {
      width: 100%;
      float: left;
      margin-top: 20px;
      padding: 10px 0px;
      height: auto;
      background-color: #52c41a;
      border-color: #52c41a;
      color: white;
      font-size: 15px;
      font-weight: bold;
    }
  }
  /* real-estate by package */
  .real-estate-package {
    padding-left: 20px;
    @media screen and (max-width: $screen-md) {
      padding-left: 0px;
      margin-top: 10px;
    }
  }
}

.real-estates.vip-1 {
  border-top: 1px solid #ffcc00 !important;
  .title-item {
    padding-top: 15px !important;
  }
  .label {
    width: 0;
    height: 0;
    border-top: 50px solid #ffcc00;
    border-left: 60px solid transparent;
    position: relative;
    color: white;
    margin-top: -1px;
    position: absolute;
    right: 0;
    .text {
      position: absolute;
      top: -45px;
      font-size: 14px;
      right: 5px;
      text-align: center;
      font-family: arial;
      transform: rotate(45deg);
      display: block;
    }
  }
}
.real-estates.vip-2 {
  .label {
    position: absolute;
    right: 17px;
    bottom: 50%;
    .text {
      font-size: 15px;
      padding: 5px 10px;
      background-color: #52c41a;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.real-estates.vip-3 {
  background-color: #fbf3ae;
  .label {
    position: absolute;
    right: 10px;
    bottom: 50%;
    .text {
      font-size: 15px;
      padding: 5px 7px;
      background-color: red;
      color: white;
      border-radius: 5px;
      font-weight: bold;
    }
  }
  .contact {
    padding: 10px;
    display: flex;
    button {
      float: right;
    }
    .ant-btn-primary {
      margin-right: 10px;
      background-color: #bd0000 !important;
      border-radius: 100px;
      height: 100%;
    }
    .ant-btn-danger {
      background-color: #bf8100;
      border-radius: 100px;
      height: 100%;
      border-color: transparent;
    }
    .zalo-img {
      border-radius: 100px;
      margin-left: 10px;
      height: 100%;
      margin-right: 10px;
    }
  }
}
