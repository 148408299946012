@mixin flexbox(
  $align-items: stretch,
  $justify-content: flex-start,
  $direction: row,
  $wrap: nowrap
) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $direction;
  flex-wrap: $wrap;
}
@mixin maxWidth($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}
@mixin textClamp($rows: 1, $size: 100%) {
  display: -webkit-box !important;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $size;
  max-width: $size;
  white-space: pre-wrap;
  word-break: break-all;
}

