.list-vertical-menu {
  .ant-list-item {
    color: #fff;
    background: #7b7b7b;
    &.active {
      background: #bf8100;
    }
    &:hover {
      background: #bf8100;
    }
  }

  .ant-list {
    background: #fff;
  }

  .ant-list-bordered {
    border: unset;
  }

  .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #e8e8e8;
  }

  a {
    color: unset;
  }
}

a {
  color: unset;
  &:hover {
    color: #cc2621;
  }
}
