.filterBar{
  display: flex;
  padding: 10px 20px;
  background-color: white;
  align-items: center;
}
.mapMobile{
  :global .ant-drawer-body{
    height: 100%;
  }
}