.actionWrapper {
  display: flex;
  flex-direction: column;
}
.commonBtn {
  height: 18px;
  line-height: 16px;
  padding: 0px 4px;
  border-radius: 4px;
  color: #ffffff;
  display: block !important;
  border: 0;
  margin-bottom: 8px;
  & > span {
    font-size: 12px;
  }
  &:hover {
    color: #ffffff;
    & > span {
      text-decoration: underline;
    }
  }
}
